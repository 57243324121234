.support-content{
    width: 100%;
    padding: 30px 60px;
    background: $baseWhite;
    position: relative;
    padding-bottom: 130px;
    @media(max-width:1450px){
        padding: 30px 35px;
        padding-bottom: 80px;
    }
    @media(max-width:1024px){
        padding: 30px 10px;
        padding-bottom: 75px;
    }
    .support__container {
        width: 100%;
        position: relative;
        max-width: 1520px;
        margin: 0 auto;
    }

    .support__title{
        font-family: Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 36px;
        color: $baseBlack;
        margin-bottom: 20px;
        @media(max-width:1024px){
            margin-bottom: 10px;

        }
    }

    .order__values {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 14px 25px;
        background: #F2F6FC;
        border-radius: 6px;
        @media(max-width:1450px){
            padding:14px 15px;
        }
        @media(max-width:1250px){
            padding:14px 10px;
        }
        @media(max-width:1024px){
            display: none;
        }
    }

    .order__value{
        font-family: Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 32px;
        color: $baseBlack;
        padding-right: 20px;
        @media(max-width:1450px){
            font-size: 15px;
            line-height: 30px;
        }
        @media(max-width:1024px){
            font-size: 14px;
            line-height: 26px;
        }
        &:nth-child(1){
            width: 100%;
            max-width:153px;
            min-width: 153px;
        }
        &:nth-child(2){
            width: 100%;
            max-width: 981px;
            min-width: 400px;
        }
        &:nth-child(3){
            width: 100%;
            max-width: 123px;
            min-width: 123px;
        }
        &:nth-child(4){
            width: 100%;
            max-width: 210px;
            max-width: 210px;
            padding-right: 0;
        }
    }

    .order__items {
        width: 100%;
        position: relative;
    }

    

    .order__item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 22px 25px;
        border-bottom: 1px solid #D0D8E4;
        @media(max-width:1450px){
            padding: 22px 15px;
        }
        @media(max-width:1250px){
            padding: 22px 10px;
        }
        @media(max-width:1024px){
            padding: 0;
            margin-bottom: 30px;
            flex-direction: column;
            align-items: flex-start;
            padding-bottom: 30px;
            &:last-of-type{
                margin-bottom: 0;
            }
        }
    }

    .item__inner{
        display: flex;
        align-items: center;
        width: 100%;
        @media(max-width:1024px){
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .order__option{
        padding-right: 20px;
        @media(max-width:1024px){
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 10px;
            padding: 0;
            &:last-of-type{
                margin-bottom: 0;
            }
        }
        .order__text{
            font-family: Gilroy;
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            color: $baseBlack;
            @media(max-width:1450px){
                font-size: 15px;
                line-height: 22px;
            }
            @media(max-width:1250px){
                font-size: 14px;
                line-height: 18px;
            }
            @media(max-width:1024px){
                font-size: 16px;
                line-height: 24px;
            }
        }

        .order__value{
            display: none;
            @media(max-width:1024px){
                display: block;
                font-family: Gilroy;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 32px;
                color: $baseBlack;
                padding: 0;
                max-width: 100%;
                min-width: auto;
            }
        }

        
        
        &:nth-child(1){
            width: 100%;
            max-width:153px;
            min-width: 153px;
            @media(max-width:1024px){
                min-width: auto;
                max-width: 100%;
            }
        }
        &:nth-child(2){
            width: 100%;
            max-width: 981px;
            min-width: 400px;
            @media(max-width:1024px){
                min-width: auto;
                max-width: 100%;
            }
        }
        &:nth-child(3){
            width: 100%;
            max-width: 123px;
            min-width: 123px;
            @media(max-width:1024px){
                min-width: auto;
                max-width: 100%;
            }
        }
        &:nth-child(4){
            width: 100%;
            max-width: 210px;
            max-width: 210px;
            padding-right: 0;
            @media(max-width:1024px){
                min-width: auto;
                max-width: 100%;
            }
        }
    }


}