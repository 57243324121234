.category-hero {
    width: 100%;
    position: relative;
    background: $baseWhite;
    padding: 30px 60px;
    padding-bottom: 20px;
    // max-width: 1520px;
    margin: 0 auto;
    @media(max-width:1450px){
        padding: 30px 35px;
        padding-bottom: 20px;
    }
    @media(max-width:1024px){
        padding: 20px 10px;
    }
    .hero__container {
        width: 100%;
        max-width: 1520px;
        margin: 0 auto;
    }
    .hero__category {
        width: 100%;
        position: relative;
    }
    .category__title{
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 48px;
        color: $baseBlack;
        max-width: 100%;
        margin-bottom: 40px;
        position: relative;
        @media(max-width:1024px){
            font-size: 26px;
            line-height: 32px;
            margin-bottom: 25px;
        }

        &:after{
            width:140px ;
            height: 4px;
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            transform: translateY(-30px);
            background: $basePink;
            border-radius: 3px;
            @media(max-width:1024px){
                transform: translateY(-18px);
            }
        }
    }

    .category__container{
        width: calc(100% + 30px);
        position: relative;
        margin: 0 auto;
        margin-left: -15px;
        display: flex;
        flex-wrap: wrap;
        @media(max-width:1024px){
            width: calc(100% + 10px);
            margin-left: -5px;
        }
    }
    .category__item{
        width: 25%;
        padding: 0 15px;
        min-height: 200px;
        margin-bottom: 30px;
        max-height: 200px;
        @media(max-width:1450px){
            width: 33.333333333%;
        }
        @media(max-width:1024px){
            padding: 0 5px;
            margin-bottom: 10px;
            min-height: 120px;
            max-height: 120px;
        }
        @media(max-width:850px){
            width: 50%;
        }
        @media(max-width:576px){
            width: 100%;
        }

        &.end-category{
            .category__inner{
                flex-direction: column;
                align-items: center;
                justify-content: center;

            }
            .category__name{
                text-align: center;
                margin-top: 14px;
                @media(max-width:1024px){
                    margin-top: 12px;
                }
            }
        }
    }
    .category__inner{
        width: 100%;
        height: 100%;
        display: flex;
        position: relative;
        border-radius: 16px;
        padding: 35px 30px;
        z-index: 1;
        background: $lightBlue;
        overflow: hidden;

        @media(max-width:1024px){
            padding: 28px 25px;
        }

    }

    .category__photo{
        position: absolute;
        z-index: -1;
        right: 0;
        top: 0;
        max-height: 200px;
        max-width: 200px;
        @media(max-width:1600px){
            max-width: 160px;
        }
        @media(max-width:1024px){
            max-width: 120px;
            min-height: 120px;
            max-height: 120px;
        }
        img{
            height: 100%;
            max-height: 200px;
            min-height: 200px;
            object-fit: contain;
            @media(max-width:1024px){
                max-width: 120px;
                min-height: 120px;
                max-height: 120px;
            }
        }

    }

    .icon-refresh{
        width: 45px;
        height: 45px;
        min-height: 45px;
        min-width: 45px;
        fill: $baseBlack;
        @media(max-width:1024px){
            width: 30px;
            height: 30px;
            min-height: 30px;
            min-width: 30px;

        }
    }

    .category__name{
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        color: $baseBlack;
        @media(max-width:1024px){
            font-size: 16px;
            line-height: 20px;

        }
    }
  .filter-button{
    border-top: 1px solid #D0D8E4;
    justify-content: space-between;
    padding: 10px;
    display: none;
    align-items: center;
    @media(max-width:1024px){
      width: calc(100% + 20px);
      margin-left: -10px;
      cursor: pointer;
      display: flex;
    }
    span{
      font-family: Gilroy;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: #252525;
    }
    .icon{
      stroke: #252525;
      stroke-width: 1.2px;
      width: 26px;
      height: 26px;
    }
  }
}

// breadcrumbs


.breadcrumbs{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
    @media(max-width:1024px){
        margin-bottom: 20px;

    }
    .breadcrumbs__item{
        margin-right: 10px;
        position: relative;
        &:last-of-type{
            margin-right: 0;
        }
        &:after{
            content: "/";
            position: absolute;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 17px;
            color: #828282;
            transition: .3s;
            right: 0;
            top: 50%;
            transform: translateY(-40%) translateX(9px);

        }
        &:last-of-type:after{
            display: none;
        }
    }

    .breadcrumbs__link{
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 17px;
        color: #828282;
        transition: .3s;
        &:hover{
            color: $baseBlue;
        }
    }
}
