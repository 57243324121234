/**
    Functions for ViewPort Calculating
============
  SCSS
  .selector {
    font-size: vw(18);
    @media $tablet
      font-size: tvw(14);
    @media $mobile
      font-size: mvw(12);
  }

  CSS RESULT
  .selector {
    letter-spacing: 1.68px;
  }
============
 */
@function vw($target, $ViewPort: $ViewPort, $digits:2) {
  @if $ViewPort == false {
    @return unquote($target + 'px')
  }
  $n: 1;
  @for $i from 1 through $digits {
    $n: $n * $point;
  }
  @return unquote(round($target/($pc * .01) * $n) / $n + 'vw');
}

@function tvw($target, $ViewPort: $ViewPort, $digits:2) {
  @if $ViewPort == false {
    @return unquote($target + 'px')
  }
  $n: 1;
  @for $i from 1 through $digits {
    $n: $n * $point;
  }
  @return unquote(round($target/($tablet * .01) * $n) / $n + 'vw');
}

@function mvw($target, $ViewPort: $ViewPort, $digits:2) {
  @if $ViewPort == false {
    @return unquote($target + 'px')
  }
  $n: 1;
  @for $i from 1 through $digits {
    $n: $n * $point;
  }
  @return unquote(round($target/($mob * .01) * $n) / $n + 'vw');
}

/**
  function VA => use for calculate letter-spacing from photoshop
============
  SCSS
  .selector {
    letter-spacing: VA(120, 14);
  }

  CSS RESULT
  .selector {
    letter-spacing: 1.68px;
  }
============
  $unit => VA value in photoshop
  $font_size => how px font-size in photoshop ||| default 16
  $return px | em ||| default px
 */
@function VA($unit, $font_size: '16', $return: 'px') {
  @if $return == 'em' {
    @return unquote(($unit / 1000) + 'em');
  } @else {
    @return unquote((($unit * $font_size) / 1000) + 'px');
  }
}
