.catalog__info {
    width: 100%;
    position: relative;
    background: $baseWhite;
    padding: 70px 60px;
    padding-bottom: 150px;
    @media(max-width:1450px){
        padding: 70px 35px;
        padding-bottom: 150px;
    }
    @media(max-width:1024px){
        padding: 20px 10px;
        padding-bottom: 70px;
    }
    
    .info__container{
        width: 100%;
        max-width: 1520px;
        margin: 0 auto;
    }

    .info__show-btn{
        display: none;
        @media(max-width:1024px){
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: none;
            outline: none;
            background: transparent;
            margin-top: 15px;
            cursor: pointer;
            transition: .3s;
            font-family: Gilroy;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 16px;
            color: $basePink;
            &.active{
                display: none;
            }
        }
        
    }

    .info__text  {
        width: 100%;
        position: relative;
        @media(max-width:1024px){
            -webkit-line-clamp: 15;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden; 
        }

        &.active{
            @media(max-width:1024px){
                -webkit-line-clamp: initial;
            }
        }


        h2{
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            line-height: 48px;
            color: $baseBlack;
            max-width: 100%;
            margin-bottom: 6px;
            position: relative;
            @media(max-width:1024px){
                font-size: 26px;
                line-height: 32px;
                margin-bottom: 5px;
                padding-top: 20px;;
            }
    
            &:after{
                width:140px ;
                height: 4px;
                position: absolute;
                content: "";
                left: 0;
                top: 0;
                transform: translateY(-30px);
                background: $basePink;
                border-radius: 3px;
                @media(max-width:1024px){
                    transform: translateY(0);
                }
            }
        }

        p{
            font-family: Gilroy;
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 36px;
            color: $baseBlack;
            margin-bottom: 27px;
            @media(max-width:1024px){
                font-size: 16px;
                line-height: 30px;
                margin-bottom: 25px;

            }
        }

        h3 {
            font-family: Gilroy;
            font-style: normal;
            font-weight: bold;
            font-size: 26px;
            line-height: 36px;
            color: $baseBlack;
            margin-bottom: 6px;
            @media(max-width:1024px){
                font-size: 20px;
                line-height: 30px;
                margin-bottom: 5px;

            }
        }

        ul{ 
            margin-bottom: 26px;
            padding-left: 20px;
            li{
                list-style-type: disc;
                font-family: Gilroy;
                font-style: normal;
                font-weight: 300;
                font-size: 18px;
                line-height: 36px;
                color: $baseBlack;
                @media(max-width:1024px){
                    font-size: 16px;
                    line-height: 30px;
    
                }

                &::marker{
                    font-size: 11px;

                }
            }
        }
    }
}