.about-advantage {
    width: 100%;
    position: relative;
    background: $baseWhite;
    padding: 30px 60px;
    padding-bottom: 130px;
    @media(max-width:1450px){
        padding: 30px 35px;
        padding-bottom: 130px;
    }
    @media(max-width:1024px){
        padding:20px 10px;
        padding-bottom: 55px;
    }
    .advantage__container{
        width: 100%;
        max-width: 1520px;
        margin: 0 auto;
    }
}