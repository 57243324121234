.search {
  .container {
    max-width: 1640px;
    padding-left: 60px;
    padding-right: 60px;
    margin: 0 auto;
    @media(max-width:1450px){
      padding-left: 35px;
      padding-right: 35px;
      max-width: 1590px;
    }
    @media(max-width:1024px){
      padding-left: 35px;
      padding-right: 35px;
    }
    @media(max-width:1024px){
      padding-right: 10px;
      padding-left: 10px;
    }
  }
  .empty-results__title {
    @media(max-width: 576px){
      font-size: 18px;
      line-height: 30px;
    }
  }
  .empty-results__subtitle {
    @media(max-width: 576px){
      font-size: 16px;
      line-height: 30px;
    }
  }
  &-wrap {
    padding: 20px 0;
  }
}
