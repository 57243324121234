.order-content{
    width: 100%;
    padding: 30px 60px;
    background: $baseWhite;
    position: relative;
    padding-bottom: 200px;
    @media(max-width:1450px){
        padding: 30px 35px;
        padding-bottom: 150px;
    }
    @media(max-width:1024px){
        padding: 30px 10px;
        padding-bottom: 75px;
    }
    .order__container {
        width: 100%;
        position: relative;
        max-width: 1520px;
        margin: 0 auto;
    }

    .order__block {
        width: 100%;
        position: relative;
        margin-bottom: 50px;

    }

    .order__values {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 14px 25px;
        background: #F2F6FC;
        border-radius: 6px;
        padding-right: 180px;
        @media(max-width:1450px){
            padding-left: 15px;
            padding-right: 170px;
        }
        @media(max-width:1250px){
            padding-left: 10px;
        }
        @media(max-width:1024px){
            display: none;
        }
    }

    .order__value{
        font-family: Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 32px;
        color: $baseBlack;
        padding-right: 20px;
        @media(max-width:1450px){
            font-size: 15px;
            line-height: 30px;
        }
        @media(max-width:1024px){
            font-size: 14px;
            line-height: 26px;
        }
        &:nth-child(1){
            width: 100%;
            max-width:175px;
            min-width: 175px;
            @media(max-width:1250px){
                max-width: 130px;
                min-width: 130px;
            }
        }

        &:nth-child(2){
            width: 100%;
            max-width: 348px;
            min-width: 270px;
            padding-right: 30px;
            @media(max-width:1450px){
                // padding-right: 20px;
                min-width: 220px;
            }
            @media(max-width:1250px){
                min-width: 170px;
            }
        }
        &:nth-child(3){
            width: 100%;
            max-width: 206px;
        }
        &:nth-child(4){
            width: 100%;
            max-width: 201px;
        }
        &:nth-child(5){
            width: 100%;
            max-width: 204px;
        }
        &:nth-child(6){
            width: 100%;
            max-width:180px;
        }
    }

    .order__items {
        width: 100%;
        position: relative;
    }

    

    .order__item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 22px 25px;
        padding-right: 15px;
        border-bottom: 1px solid #D0D8E4;
        @media(max-width:1450px){
            padding: 22px 15px;
        }
        @media(max-width:1250px){
            padding: 22px 10px;
        }
        @media(max-width:1024px){
            padding: 0;
            margin-bottom: 30px;
            flex-direction: column;
            align-items: flex-start;
            padding-bottom: 30px;
            &:last-of-type{
                margin-bottom: 0;
            }
        }
    }

    .item__inner{
        display: flex;
        align-items: center;
        width: calc(100% - 160px);
        @media(max-width:1024px){
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
        }
    }

    .order__option{
        padding-right: 20px;
        @media(max-width:1024px){
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 10px;
            padding: 0;
            &:last-of-type{
                margin-bottom: 20px;
            }
        }
        .order__text{
            font-family: Gilroy;
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            color: $baseBlack;
            @media(max-width:1450px){
                font-size: 15px;
                line-height: 22px;
            }
            @media(max-width:1250px){
                font-size: 14px;
                line-height: 18px;
            }
            @media(max-width:1024px){
                font-size: 16px;
                line-height: 24px;
            }
        }

        .order__value{
            display: none;
            @media(max-width:1024px){
                display: block;
                font-family: Gilroy;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 32px;
                color: $baseBlack;
                padding: 0;
                max-width: 100%;
                min-width: auto;
            }
        }

        
        
        &:nth-child(1){
            width: 100%;
            max-width:175px;
            min-width: 175px;
            @media(max-width:1250px){
                max-width: 130px;
                min-width: 130px;
            }
            @media(max-width:1024px){
                max-width: 100%;
                min-width: auto;
                margin-bottom: 25px;
            }
        }

        &:nth-child(2){
            width: 100%;
            max-width: 348px;
            min-width: 270px;
            padding-right: 30px;
            @media(max-width:1450px){
                // padding-right: 20px;
                min-width: 220px;
            }
            @media(max-width:1250px){
                min-width: 170px;
            }
            @media(max-width:1024px){
                max-width: 100%;
                min-width: auto;
                padding: 0;
            }
        }
        &:nth-child(3){
            width: 100%;
            max-width: 206px;
            @media(max-width:1024px){
                max-width: 100%;
                min-width: auto;
            }
        }
        &:nth-child(4){
            width: 100%;
            max-width: 201px;
            @media(max-width:1024px){
                max-width: 100%;
                min-width: auto;
            }
        }
        &:nth-child(5){
            width: 100%;
            max-width: 204px;
            @media(max-width:1024px){
                max-width: 100%;
                min-width: auto;
            }
        }
        &:nth-child(6){
            width: 100%;
            max-width:180px;
            @media(max-width:1024px){
                max-width: 100%;
                min-width: auto;
            }
        }
    }

    .order__photo{
        width: 130px;
        height: 116px;
        background: $baseBack;
        border-radius: 8px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        @media(max-width:1250px){
            width: 110px;
            height: 110px;
        }
        @media(max-width:1024px){
            width: 130px;
            height: 116px;
        }
        img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
        }
    }



    .order-item__btn{
        display: inline-flex;
        min-height: 48px;
        background: linear-gradient(90deg, #DC2C43 0%, #FF5369 100%);
        box-shadow: 0px 4px 4px rgba(233, 58, 81, 0.2);
        border-radius: 36px;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        /* or 129% */
        min-width: 160px;
        max-width: 160px;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 10px 15px;
        color: $baseWhite;
        transition: .3s;
        position: relative;
        overflow: hidden;
        z-index: 1;
        outline: none;
        border: none;
        cursor: pointer;

        &:after{
            content: "";
            position: absolute;
            top: 0;
            z-index: -1;
            transition: .3s;
            opacity: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, #D21E35 0%, #EE3149 100%);
        }
        &:hover:after{
            opacity: 1;
        }
    }
}