.support__form {
    width: 100%;
    position: relative;
    padding-top: 50px;
    @media(max-width:1024px){
      padding-top: 38px;      
    } 

    .form__block{
        width: 100%;
        max-width: 830px;
        position: relative;
        @media(max-width:1024px){
            max-width: 100%;
        }
    }

    .form__title{
        font-family: Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 36px;
        color: $baseBlack; 
        margin-bottom: 3px; 
        
    }

    .form__row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        margin-bottom: 20px;
        @media(max-width:1024px){
            margin-bottom: 15px;
        }
        @media(max-width:700px){
            flex-direction: column;

        }
        .form-item {
            width: calc(50% - 15px);
            @media(max-width:1024px){
                width: calc(50% - 7.5px);
            }
            @media(max-width:700px){
                width: 100%;
                margin-bottom: 15px;
                &:last-child{
                    margin: 0;
                }
            }
        }
    }

    .form-item{
        width: 100%;
        position: relative;
        line-height: 100%;

        &.error,  &.error__email{
            input, textarea{
                border-color: $basePink;
            }
        }
        
    }

    .item__title{
        font-family: Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 36px;
        color: $baseBlack;
        margin-bottom: 4px;
    }

    .form__input{
        min-height: 60px;
        background: #FCFDFF;
        /* 5 */
        border: 1px solid #C0C9D7;
        box-sizing: border-box;
        border-radius: 6px;
        outline: none;
        width: 100%;
        padding: 10px 20px;
        font-family: Gilroy;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 36px;
        color: $baseBlack;
        transition: .3s;
        @media(max-width:570px){
            font-size: 16px;
        }
    }

    .form__arrea{
        min-height: 120px;
        background: #FCFDFF;
        /* 5 */
        border: 1px solid #C0C9D7;
        box-sizing: border-box;
        border-radius: 6px;
        outline: none;
        width: 100%;
        padding: 12px 20px;
        font-family: Gilroy;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        color: $baseBlack;
        transition: .3s;
        margin: 0;
        @media(max-width:570px){
            font-size: 16px;
        }
    }

    .form__btn{
        margin-top: 30px;
        display: inline-flex;
        min-height: 60px;
        background: linear-gradient(90deg, #DC2C43 0%, #FF5369 100%);
        box-shadow: 0px 4px 4px rgba(233, 58, 81, 0.2);
        border-radius: 36px;
        font-style: normal;
        font-weight: bold;
        font-family: Gilroy;
        font-size: 15px;
        line-height: 18px;
        /* or 129% */
        min-width: 200px;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 10px 15px;
        color: $baseWhite;
        transition: .3s;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        z-index: 1;
        border: none;
        outline: none;
        @media(max-width:1024px){
            margin-top: 20px;
        }

        &:after{
            content: "";
            position: absolute;
            top: 0;
            z-index: -1;
            transition: .3s;
            opacity: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, #D21E35 0%, #EE3149 100%);
        }
        &:hover:after{
            opacity: 1;
        }
    }
}