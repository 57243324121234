.contacts-info {
    width: 100%;
    position: relative;
    background-color: $baseWhite;
    padding: 30px 60px;
    padding-bottom: 40px;
    @media(max-width:1450px){
        padding: 20px 35px;
        padding-bottom: 40px;
    }
    @media(max-width:1024px){
        padding: 10px;
        padding-top: 20px;
    }


    .info__container{
        width: 100%;
        max-width: 1520px;
        margin: 0 auto;
        position: relative;
    }

    .contacts__title{
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 48px;
        color: $baseBlack;
        max-width: 100%;
        margin-bottom: 30px;
        position: relative;
        max-width: 1000px;
        @media(max-width:1024px){
            font-size: 26px;
            line-height: 32px;
            margin-bottom: 25px;
            padding: 0;
        }
        &:after{
            width:140px ;
            height: 4px;
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            transform: translateY(-30px);
            background: $basePink;
            border-radius: 3px;
            @media(max-width:1024px){
                transform: translateY(-18px);
            }
        }
    }

    .contacts__block {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: space-between;
        @media(max-width:1024px){
            flex-direction: column;

        }

    }

    .contact__map{
        width: 100%;
        max-width:1080px;
        margin-right: 80px;
        min-height: 560px;
        position: relative;
        @media(max-width:1450px){
            margin-right: 50px;
        }
        @media(max-width:1024px){
            max-width: 100%;
            margin-bottom: 45px;
        }
    }

    .contact__information {
        width: 100%;
        max-width: 360px;
        position: relative;
        @media(max-width:1450px){
            max-width: 300px;
        }
        // @media(max-width:1024px){
        //     max-width: 100%;
        //     display: flex;
        //     flex-wrap: wrap;
        // }
    }

    .info__item {
        display: flex;
        margin-bottom: 25px;
        &:last-child{
            margin-bottom: 0;
        }
        // @media(max-width:1024px){
        //     width: 33.3333333%;
        // }

    }

    .item__icon{
        width: 30px;
        height: 30px;
        margin-right: 18px;
        min-width: 30px;
        min-height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon{
            width: 30px;
            height: 30px;
            fill: transparent;
            stroke: #E64592;
            stroke-width: 1.2px;
        }
    }

    .item__side{
        width: calc(100% - 48px);
    }

    .item__title {
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 24px;
        color: $baseBlack;
        margin-bottom: 4px;
    }

    .item__info{
        display: flex;
        flex-direction: column;
        a, p {
            font-style: normal;
            font-weight: bold;
            font-size: 17px;
            line-height: 22px;
            color: $baseBlack;
            transition: .3s;
        }
    }

    .contact__social {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 25px;

    }    
    .social__item {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: 1px solid $basePink;
        margin-right: 6px;
        margin-bottom: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s;
        .icon{
            transition: .3s;
            fill: $basePink;
        }

        &:last-child{
            margin-right: 0;
        }
    }

    .icon-facebook{
        width: 8px;
        height: 15px;
    }
    .icon-instagram{
        width: 14px;
        height: 14px;

    }

    .icon-youtube{
        width: 16px;
        height: 12px;
    }

    .leaflet-control-attribution.leaflet-control{
        display: none !important;
    }
    .contact__map{
        filter: grayscale(15%);
        z-index: 1;
        position: relative;
    }
}