$ViewPort: false;
// LIBS
@import "libs";

// Custom mixins
@import 'vars/index';
@import 'utils/fonts';
@import 'utils/mixins';
*{
  margin: 0;
  padding: 0;
}
body {
  font-family: 'Gilroy', sans-serif;
  font-weight: normal;
  line-height: 1.3;
  font-size: vw(16);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}


.index-page{
  @media(max-width:1024px){
    .content{
      display: flex;
      flex-direction: column;
    }
    .homepage-category{
      order: -1;
    }
  }
}
.lock{
  overflow:hidden ;
  touch-action: none;
}
html{
  scroll-behavior: smooth;
}
h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

ul, ol, li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

textarea {
  resize: none;
}

section {
  position: relative;
}

input,
select,
button,
textarea {
  outline: none !important;
}

*, *::before, *::after {
  box-sizing: border-box;
}

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.content {
  flex: 1 0 auto;
}



// Modules & Components styles
@import "modules";
@import "components";
