$baseWhite: #fff;
$baseBlack: #252525;
$secondaryBlack: #434343;
$baseBlue: #2C4378;
$baseLightGrey: #C0C9D7;
$baseGrey: #D0D8E4;
$lightGray: #F2F6FC;
$secondaryGray: #828282;
$basePink: #DC2C43;
$baseHover:#D62038;
$lightBlue: #DBF0FF;
$secondaryBlue: #FCFDFF;
$baseBack: #F5F9FE;
$baseGreen: #27AE60;
$BlueHover: #213768;
