.homepage-category{
    width: 100%;
    position: relative;
    background: $baseWhite;
    padding: 40px 45px;
    padding-bottom: 50px;
    @media(max-width:1450px){
        padding: 40px 20px;
        padding-bottom: 50px;
    }
    @media(max-width:1024px){
        padding: 15px 5px;
        padding-bottom: 30px;
    }
    .category__container{
        max-width: 1550px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        // @media(max-width:1450px){
        //     padding: 0 10px;
        // }
    }
    .category__item{
        width: 25%;
        padding: 0 15px;
        min-height: 200px;
        margin-bottom: 30px;
        max-height: 200px;
        @media(max-width:1450px){
            width: 33.333333333%;
        }
        @media(max-width:1024px){
            padding: 0 5px;
            margin-bottom: 10px;
            min-height: 120px;
            max-height: 120px;
        }
        @media(max-width:850px){
            width: 50%;
        }
        @media(max-width:576px){
            width: 100%;
        }

        &.end-category{
            .category__inner{
                flex-direction: column;
                align-items: center;
                justify-content: center;

            }
            .category__name{
                text-align: center;
                margin-top: 14px;
                @media(max-width:1024px){
                    margin-top: 12px;
                }
            }
        }
    }
    .category__inner{
        width: 100%;
        height: 100%;
        display: flex;
        position: relative;
        border-radius: 16px;
        padding: 35px 30px;
        z-index: 1;
        background: $lightBlue;
        overflow: hidden;

        @media(max-width:1024px){
            padding: 28px 25px;
        }
        
    }

    .category__photo{
        position: absolute;
        z-index: -1;
        right: 0;
        top: 0;
        max-height: 200px;
        max-width: 200px;
        @media(max-width:1600px){
            max-width: 160px;
        }
        @media(max-width:1024px){
            max-width: 120px;
            min-height: 120px;
            max-height: 120px;
        }
        img{
            height: 100%;
            max-height: 200px;
            min-height: 200px;
            object-fit: contain;
            @media(max-width:1024px){
                max-width: 120px;
                min-height: 120px;
                max-height: 120px;
            }
        }

    }

    .icon-refresh{
        width: 45px;
        height: 45px;
        min-height: 45px;
        min-width: 45px;
        fill: $baseBlack;
        @media(max-width:1024px){
            width: 30px;
            height: 30px;
            min-height: 30px;
            min-width: 30px;

        }
    }

    .category__name{
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        color: $baseBlack;
        @media(max-width:1024px){
            font-size: 16px;
            line-height: 20px;

        }
    }
}