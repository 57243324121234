.cart {
  &__form {
    .form {
      padding: 33px 37px 40px 37px;
      background: $baseBack;
      border-radius: 8px;
      @media(max-width: 768px){
        padding: 25px 23px 40px;
      }
    }
  }
  .form {
    &__section-title {
      font-weight: bold;
      font-size: 20px;
      line-height: 36px;
      margin-bottom: 12px;
    }
    &-item {
      margin-bottom: 23px;
      &.error {
        .form-item__input {
          border-color: $basePink;
        }
      }
      &__title {
        font-weight: bold;
        margin-bottom: 8px;
        color: $secondaryBlack;
        .required {
          position: relative;
          &:after {
            content: '*';
            position: absolute;
            top: 0;
            right: -6px;
            color: $basePink;
          }
        }
      }
      &__text {
        flex: 1 1;
        font-size: 14px;
        line-height: 17px;
      }
      &__input {
        font-family: Gilroy, serif;
        padding: 20px 22px;
        font-size: 16px;
        width: 100%;
        height: 56px;
        background: $baseWhite;
        border: 1px solid $baseGrey;
        border-radius: 6px;
        transition: all .3s ease;
        &::-webkit-input-placeholder {
          font-family: Gilroy, serif;
          font-weight: 300;
          font-size: 13px;
        }
        &:-moz-placeholder {
          font-family: Gilroy, serif;
          font-weight: 300;
          font-size: 13px;
        }
        &::-moz-placeholder {
          font-family: Gilroy, serif;
          font-weight: 300;
          font-size: 13px;
        }
        &:-ms-input-placeholder {
          font-family: Gilroy, serif;
          font-weight: 300;
          font-size: 13px;
        }
        &::-ms-input-placeholder {
          font-family: Gilroy, serif;
          font-weight: 300;
          font-size: 13px;
        }

        &::placeholder {
          font-family: Gilroy, serif;
          font-weight: 300;
          font-size: 13px;
        }
      }
      &__radio {
        display: none;
        &:checked + .form-item__radio-elem:after {
          opacity: 1;
        }
        &-elem {
          position: relative;
          width: 30px;
          height: 30px;
          display: block;
          background: $baseWhite;
          border: 1px solid $baseGrey;
          box-sizing: border-box;
          border-radius: 50%;
          margin-right: 13px;
          &:after {
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            top: 8px;
            left: 8px;
            border-radius: 50%;
            background: $basePink;
            transition: all .3s ease;
            opacity: 0;
          }
        }
      }
    }
    label.form-item {
      cursor: pointer;
      display: inline-flex;
      align-items: center;
    }
    &-item__textarea {
      height: 120px;
    }
  }
}
