#login-modal {
  .modal__title {
    max-width: 260px;
    margin-bottom: 15px;
  }
  .form {
    &-info {
      span {
        margin: 0 5px
      }
      @media(max-width: 568px){
        margin-bottom: 15px;
      }
    }
    &-btns {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      .default-btn {
        width: 100%;
        max-width: 200px;
        margin-right: 10px;
        @media(max-width: 568px){
          margin-bottom: 15px;
        }
      }
      &__social {
        display: flex;
        align-items: center;
        @media(max-width: 568px){
          width: 100%;
        }
        &-item {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 7px;
          cursor: pointer;
          position: relative;
          transition: all .3s ease;
          &:hover {
            transform: translateY(-3px);
          }
          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
