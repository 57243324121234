.comparison-hero {
  width: 100%;
  position: relative;
  background: $baseWhite;
  padding: 20px 0 10px;
  @media(max-width: 768px){
    padding-bottom: 0;
  }
  .hero__container {
    width: 100%;
    max-width: 1520px;
    margin: 0 auto;
  }
  .hero__comparison {
    width: 100%;
    position: relative;
  }
  .comparison__title {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
    color: $baseBlack;
    max-width: 100%;
    margin-bottom: 25px;
    position: relative;
    @media(max-width:1024px){
      font-size: 26px;
      line-height: 32px;
      margin-bottom: 25px;
    }

    &:after{
      width:140px ;
      height: 4px;
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      transform: translateY(-30px);
      background: $basePink;
      border-radius: 3px;
    }
  }
  .comparison__subtitle {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 17px;
  }
}
.comparison {
  .breadcrumbs {
    padding-left: 0;
    margin-bottom: 50px;
  }
  .container {
    max-width: 1540px;
    padding: 0 10px;
    margin: 0 auto;
  }
  .homepage__diskount {
    padding-left: 0;
    padding-right: 0;
  }
}
