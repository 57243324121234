.header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: $baseWhite;
  @media(max-width:1024px){
    flex-direction: column;
  }

  &__wrap {
    width: 100%;
    @media(max-width:1024px){
      position: fixed;
      top: 0;
      right: -120%;
      width: 100%;
      transition: .3s;
      height: 100vh;
      max-height: 100vh;
      overflow:hidden;
      overflow-y: auto;
      z-index: 20;
      background: $baseWhite;
      display: flex;
      flex-direction: column;
      padding-bottom: 160px;
      &.active{
        right: 0;
      }

    }
  }

  .header__mobile-home {
    display: none;
    @media(max-width:1024px){
      display: flex;
      width: 100%;
      padding: 15px 10px;
      border-bottom: 1px solid #D0D8E4;
      order: 2;
      justify-content: flex-end;
      align-items: center;
      .header-home__link{
        font-size: 15px;
        line-height: 17px;
        transition: .3s;
        color: $baseBlack;
        font-weight: 600;
        &:hover{
          color: $basePink;
        }
      }
    }
  }

  
  .header__top{
    width: 100%;
    position: relative;
    padding: 7px 60px;
    border-bottom: 1px solid #D0D8E4 ;
    @media(max-width:1450px){
      padding: 7px 35px;
    }
    @media(max-width:1024px){
      display: flex;
      order: 4;
      padding: 30px 10px;
      border: none;
    }

  }
  .container {
    width: 100%;
    max-width: 1520px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header-top__menu {
    display: flex;
    align-items: center;
    margin-right: 15px;
    @media(max-width:1024px){
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      margin: 0;
    }
  }

  .header-top__link{
    padding: 0 25px;
    border-right: 1px dashed #D0D8E4;
    min-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: Gilroy;
    transition: .3s;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: $baseBlack;
    @media(max-width:1024px){
      padding: 0;
      border: none;
      font-size: 15px;
      line-height: 16px;
      margin-bottom: 18px;
    }


    &:first-of-type{
      padding-left: 0;
    }
    &:last-of-type{
      border: none;
      padding-right: 0;
      @media(max-width:1024px){
        margin-bottom: 0;
      }

    }

    &:hover{
      color: $basePink;
    }
  }
.header-top__lang{
  @media(max-width:1024px){
    display: none;
  }
//select dropdown
.select-info.selecter.form-item.active {
  max-width: 100%;
  box-shadow: none;
}

.select-info.active{
  .icon-select{
    transform: rotate(180deg);
  }
}

.select-info{
  box-sizing: border-box;
  position: relative;
  width:100%;
  background: transparent;
  transition: .3s; 
  min-width: 50px;
  max-width: 50px;
}

.select__value{
  width: 31px;
  height: 24px;
  border: 1px solid #D0D8E4;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  .lang__img{
    width: 23px;
    height: 15px;

  }
}
.select__option{
  width: 100%;
  margin-bottom: 4px;
}
.select__option-info{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: auto;
  transition: .3s;
  width: 31px;
  height: 24px;
  padding: 4px;
  border: 1px solid $baseGrey;
  background: $baseWhite;
  border-radius: 2px;
  img{
    width: 23px;
    height: 15px;
  }
}

.select__option-info:hover{
    color: $baseBlack;
}


.select__option.active{
  box-shadow: none;
  max-width: 100%;
}

.select__dropdown {
  position: absolute;
  width: 100%;
  background: transparent;
  display: none;
  overflow-y: auto;
  z-index: 10;
  margin-top: 4px;
}

.select__dropdown.active {
  max-width: 100%;

}
.select__trigger {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  z-index: 1;
  transition: .3s;
}


.select__option.active{
  .select__option-info{
    font-weight: 600;
    color: black;
  }
}

.select-arrow {
    width: 10px;
    height: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top:50%;
    background: $baseWhite;
    transform: translateY(-50%);
    .icon{
      width: 10px;
      height: 6px;
      fill: transparent;
      transition: .3s;
      stroke: $baseBlack;
      stroke-width: 1px;
    }
  }

}



  //header catalo0g trigger
  .header-catalog__trigger {
    display: none;
    @media(max-width:1024px){
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 56px;
      padding: 10px;
      width: 100%;
      background: $baseBlue;
      cursor: pointer;
      transition: .3s;
      &:hover{
        background: $BlueHover;
      }

    }

    .trigger__name{
      font-family: Gilroy;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 19px;
      color: $baseWhite;
      margin-right: 15px;
    }

    .trigger__arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      transform: rotate(90deg);
      .icon{
        width: 16px;
        height: 10px;
        fill: transparent;
        stroke: $baseWhite;

        stroke-width: 1.2px;
      }
    }
  }
  

  // header bottom



  .header__bottom {
    padding: 0 60px;
    width: 100%;
    position: relative;
    background: $baseBlue;
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media(max-width:1450px){
      padding: 0 35px;
    }
    @media(max-width:1250px){
      min-height: 50px;
    }
    @media(max-width:1024px){
      order: 3;
      padding: 0;
      background: $baseWhite;
      min-height: auto;

    }

  }

  .header-bottom__menu {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: -36px;
    // white-space: nowrap;
    width: calc(100% + 36px);
    @media(max-width:1720px){
      width: calc(100% + 20px);
      margin-left: -20px;
    }
    @media(max-width:1450px){
      width: calc(100% + 17px);
      margin-left: -17px;
    }
    @media(max-width:1250px){
      width: calc(100% + 10px);
      margin-left: -10px;
    }
    @media(max-width:1150px){
      width: calc(100% + 7px);
      margin-left: -7px;
    }
    @media(max-width:1024px){
      width: 100%;
      margin: 0;
      flex-direction: column;
    }

    li{
      @media(max-width:1024px){
        width: 100%;
        padding: 0;
        min-height: 60px;
        border-bottom: 1px solid #D0D8E4;
        position: relative;
      }
      &:last-child{
        margin-left: auto;
        margin-right: 0;  
        .header-bottom__link:before{
          display: none;
        }
      }
      &:first-of-type{
        .header-bottom__link:before{
          display: none;
        }
      }
    }

  }

  .header-bottom__link{
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    height: 100%;
    line-height: 19px;
    color: $baseWhite;
    min-height: 70px;
    padding: 5px 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s;
    position: relative;
    &:before{
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 41px;
      border-left:1px dashed rgba(#D0D8E4, 0.4);
      @media(max-width:1250px){
        height: 30px;
        
      }
    }

    

    // &:last-of-type{
    //   padding-right: 0;
    //   border: none;
    // }

    &:hover{
      background: #213768;
      @media(max-width:1024px){
        background: transparent;
        color: $basePink;
      }
    }

    @media(max-width:1720px){
      padding: 5px 20px;
      font-size: 14px;
      line-height: 16px;
    }
    @media(max-width:1450px){
      padding: 5px 17px;
      font-size: 13px;
    }
    @media(max-width:1250px){
      padding: 5px 10px;
      font-size: 12px;
      line-height: 14px;
      min-height: 50px;
    }
    @media(max-width:1150px){
      font-size: 11px;
      padding: 5px 7px;
    }
    @media(max-width:1024px){
      padding: 0;
      width: 100%;
      height: 100%;
      text-align: left;
      justify-content: flex-start;
      min-height: auto;
      font-size: 15px;
      line-height: 20px;
      color: $baseBlack;
      min-height: 60px;
      padding: 10px;

    }


  }
  .header-option__top{
    @media(max-width:1024px){
      width: 100%;
      position: relative;
      padding-right: 30px;
    }
    

  }

  .header-option__content {
    display: none;
    @media(max-width:1024px){
      // display: block;
      width: 100%;
      padding: 10px;
      padding-top: 0;
      padding-bottom: 30px;
      li{
        border: none;
        min-height: auto;
        padding:0 ;
        margin-bottom: 18px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
    .header-adds__link{
      font-family: Gilroy;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: $baseBlack;
      transition: .3s;
      display: inline-block;
      
      &:hover{
        color: $basePink;
      }
    }
  }

  .header-option__arrow {
    display: none;
    @media(max-width:1024px){
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      position: absolute;
      cursor: pointer;
      top: 50%;
      right: 7px;
      transform: translateY(-50%) rotate(90deg);
      transition: .3s;
      .icon{
        width: 18px;
        height: 10px;
        fill: transparent;
        stroke: $baseBlack;
        stroke-width: 1.2px;
        transition: .3s;

      }
      &.active{
        .icon{
          transform: rotate(90deg);
        }
      }
    }
  }

  .header-diskount__link{
    padding: 20px 41px;
    background: $basePink;
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 70px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: $baseWhite;
    transition: .3s;
    border: none;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    
    &:hover{
      background: $baseHover;
      @media(max-width:1024px){
        background: transparent;
        color: $basePink;
      }

    }
    @media(max-width:1720px){
      padding: 5px 30px;
      font-size: 14px;
      line-height: 16px;
    }
    @media(max-width:1450px){
      padding: 5px 17px;
      font-size: 13px;
    }
    @media(max-width:1250px){
      padding: 5px 10px;
      font-size: 12px;
      line-height: 14px;
      min-height: 50px;
    }
    @media(max-width:1150px){
      font-size: 11px;
      padding: 5px 7px;
    }
    @media(max-width:1024px){
      min-height: auto;
      font-size: 15px;
      line-height: 20px;
      color: $baseBlack;
      background: transparent;
      min-height: 60px;
      padding: 10px;
    }
  }

  // header__mobile-top 
  .header__mobile-top {
    display: none;
    @media(max-width:1024px){
      display: flex;
      order: 1;
      align-items: center;
      justify-content: space-between;
      background: $baseBack;
      padding: 10px;
      min-height: 60px;

    }
    .top__name{
      font-family: Gilroy;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $baseBlack;
      margin-right: 15px;
    }
    .header__menu-close{
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: .3s;
      outline: none;
      border: none;
      background: transparent;
      .icon{
          width: 18px;
          height: 18px;
          fill: $basePink;
          transition: .3s;
          stroke-width: 0.5px;
          stroke: $basePink;

      }
      &:hover{
        .icon{
          fill: $baseHover;
          stroke: $basePink;
        }
      }
  }
  }

  // mobile lang

  .header-mobile__lang{
    display: none;
    @media(max-width:1024px){
      width: 100%;
      display: flex;
      order: 6;
      padding: 35px 10px;
      flex-wrap: wrap;
      .mobile-lang__link{
        width: 31px;
        height: 24px;
        transition: .3s;
        padding: 4px;
        border: 1px solid #D0D8E4;
        box-sizing: border-box;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 6px;
        margin-bottom: 6px;
        img{
          width: 23px;
          height: 16px;
        }
        &:last-child{
          margin-right: 0;
        }
      }
    }
    
  }

  // header main


  .header__main{
    width: 100%;
    padding: 15px 60px;
    @media(max-width:1450px){
      padding: 15px 35px;
    }
    @media(max-width:1024px){
      display: flex;
      flex-direction: column;
      order: 5;
      padding: 10px;
    }
  }

  .header__logo {
    width: 230px;
    height: 55px;
    margin-right: 15px;
    img{
      width: 100%;
      height: 100%;
    }
    @media(max-width:1300px){
      width: 172px;
      height: 41px;
    }
    @media(max-width:1150px){
      width: 151px;
      height: 37px;
    }
    @media(max-width:1024px){
      width: 140px;
      height: 33px;
      display: none;

    }
  }

  .header__search {
    width: 100%;
    max-width: 430px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 48px;
    margin-right: 15px;
    position: relative;
    @media(max-width:1720px){
      max-width: 350px;
    }
    @media(max-width:1450px){
      max-width: 270px;

    }
    @media(max-width:1150px){
      max-width: 200px;
    }
    @media(max-width:1024px){
      display: none;
    }

  }

  .search__input{
    width: 100%;
    min-height: 48px;
    outline: none;
    background: #F5F9FE;
    border: 1px solid #D0D8E4;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 5px 25px;
    padding-right: 50px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $baseBlack;
    transition: .3s;
    font-family: Gilroy;
    &:hover{
      background: transparent;

    }
    &:focus{
      background: transparent;
    }
    @media(max-width:1450px){
      padding-left: 15px;
      padding-right: 40px;
    }
  }

  .search__btn{
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 13px;
    top: 50%;
    cursor: pointer;
    transform: translateY(-50%);
    background: transparent;
    outline: none;
    border: none;
    .icon{
      width: 18px;
      height: 18px;
      fill: transparent;
      stroke-width:1.5px;
      stroke:$basePink;
      transition: .3s;
    }

  }

  .header__buttons{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 40px;
    border-left: 1px solid #D0D8E4;
    @media(max-width:1720px){
      padding-left: 25px;
    }
    @media(max-width:1450px){
      padding-left: 15px;
    }
    @media(max-width:1024px){
      display: none;
    }
  }

  .header-function__btn{
    transition: .3s;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-right: 33px;
    position: relative;
    @media(max-width:1720px){
      margin-right: 25px;
    }
    @media(max-width:1450px){
      margin-right: 15px;
      width: 25px;
      height: 25px;
    }
    .icon{
      width: 30px;
      height: 30px;
      fill: transparent;
      transition: .3s;
      stroke: #2C4378;
      stroke-width: 1.5px;
      @media(max-width:1450px){
        width: 25px;
        height: 25px;
      }
    }

    &:hover{
      .icon{
        stroke: $basePink;
      }
    }
    .btn-count{
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: $basePink;
      color: $baseWhite;
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 12px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      justify-content: center;
      text-align: center;
      transform: translateX(10px) translateY(-10px);
      @media(max-width:1450px){
        width: 20px;
        height: 20px;
        font-size: 10px;
        line-height: 10px;
      }
    }
    &:last-of-type{
      margin-right: 0;
    }
  }

  .header__contacts {
    display: flex;
    position: relative;
    margin-right: 15px;
    @media(max-width:1024px){
      flex-direction: column;

    }

  }

  .contact__item{
    display: flex;
    margin-right: 44px;
    max-width: 230px;
    @media(max-width:1450px){
      margin-right: 25px;
    }
    @media(max-width:1300px){
      margin-right: 15px;
    }
    @media(max-width:1024px){
      max-width: 100%;
      margin: 0;
      margin-bottom: 30px;
    }
    &:last-of-type{
      margin-right: 0;
      @media(max-width:1024px){
        margin-bottom: 0;
      }
    }

  }

  .contact__icon {
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    @media(max-width:1300px){
      width: 22px;
      height: 22px;
      margin-right: 8px;
    }
    @media(max-width:1024px){
      width: 26px;
      height: 26px;
      margin-right: 10px;
    }
    .icon{
      width: 26px;
      height: 26px;
      fill: transparent;
      stroke-width: 1px;
      stroke:#2C4378 ;
      @media(max-width:1300px){
        width: 22px;
        height: 22px;
      }
      @media(max-width:1024px){
        width: 26px;
        height: 26px;
      }
    }
  }

  .contact__info {
    width: calc(100% - 38px);
    @media(max-width:1300px){
      width: calc(100% - 30px);
    }
    @media(max-width:1024px){
      width: auto;
    }
    p{
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 16px;
      color: $baseBlack;
      @media(max-width:1300px){
        font-size: 12px;
        line-height: 14px;
      }
      @media(max-width:1024px){
        font-size: 13px;
        line-height: 16px;
      }
    }
  }

  .contact__block{
    width: 100%;
    position: relative;
    margin-top: 5px;
  }

  .contact__top{
    width: 100%;
    display: flex;
    padding-right: 20px;
    position: relative;
  }

  .contact__number{
    display: inline-flex;
    align-items: center;
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: $baseBlack;
    transition: .3s;
    @media(max-width:1300px){
      font-size: 14px;
      line-height: 18px;
    }
    @media(max-width:1024px){
      font-size: 16px;
      line-height: 20px;
    }

    img{
      width:22px ;
      height: 18px;
      margin-right: 9px;
    }
  }

  .contact__content {
    width: calc(100% + 20px);
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateY(100%) translateX(-50%);
    z-index: 2;
    background: $baseWhite;
    padding: 10px;
    display: none;
    border-radius: 4px;
    .contact__number{
      margin-bottom: 10px;
      &:last-of-type{
        margin-bottom: 0;
      }
    }
  }

  .contact__trigger{
    width: 12px;
    height: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top:50%;
    background: $baseWhite;
    transform: translateY(-50%);
    cursor: pointer;

    &.active{
      .icon{
        transform: rotate(180deg);
      }
    }
    .icon{
      width: 12px;
      height: 7px;
      fill: transparent;
      transition: .2s;
      stroke: $baseBlack;
      stroke-width: 1.2px;
    }
  }



  .header-catalog__menu{
    display: none;
    @media(max-width:1024px){
      display: block;
      position: fixed;
      z-index: 20;
      top: 0;
      right: -120%;
      transition: .3s;
      width: 100%;
      max-width: 100%;
      margin: 0;
      height: 100vh;
      overflow-y: auto;
      background: $baseBack;
      padding-bottom: 160px;
      &.active{
          right: 0;
      }

    }

  .hero-menu__links{
      width: 100%;
      position: relative;

  }
  .hero-menu__top {
      display: none;
      @media(max-width:1024px){
          display: flex;
          min-height: 56px;
          padding: 10px;
          align-items: center;
          justify-content: space-between;
          background: $baseBlue;
      }
      .hero-menu__name{
          font-family: Gilroy;
          font-style: normal;
          font-weight: bold;
          font-size: 15px;
          line-height: 19px;
          margin-right: 15px;
          color: $baseWhite;
      }
      .hero-menu__close{
          width: 18px;
          height: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: .3s;
          .icon{
              width: 18px;
              height: 18px;
              fill: $baseWhite;
              transition: .3s;
              stroke-width: 0.5px;
              stroke: $baseWhite;

          }
      }
  }

  .hero-category__link{
      cursor: pointer;
      background: $baseBack;
      display: flex;
      align-items: center;
      padding: 8px 13px;
      width: 100%;
      border-bottom: 1px solid #D0D8E4;
      transition: .3s;
      min-height: 47px;
      position: relative;
      padding-right: 45px;

      &:first-of-type{
          border-radius: 8px 8px 0px 0px;
          @media(max-width:1024px){
              border-radius: 0;
          }
      }

      &:last-of-type{
          border-radius: 0px 0px 8px 8px;
          border: none;
          @media(max-width:1024px){
              border-bottom: 1px solid #D0D8E4;
              border-radius: 0;
          }
      }
      @media(max-width:1024px){
          padding-right:40px ;
      }


      .link__icon{
          width: 30px;
          height: 30px;
          margin-right: 18px;
          display: flex;
          align-items: center;
          min-width: 30px;
          min-height: 30px;
          justify-content: center;
          .icon{
              width: 100%;
              height: 100%;
              max-width: 30px;
              max-height: 30px;
              object-fit: contain;
              fill: $baseBlue;
              transition: .3s;
          }
      }
  
      .link__arrow {
          width: 20px;
          height: 10px;
          position: absolute;
          right: 13px;
          top: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          transform: translateY(-50%) rotate(90deg);
          .icon{
              width: 20px;
              height: 10px;
              fill: transparent;
              stroke: $baseBlue;
              stroke-width: 1.2px;
              transition: .3s;
          }
          @media(max-width:1024px){
              right: 10px;
          }
      }

      .link__name{
          font-family: Gilroy;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: $baseBlack;
          transition: .3s;
      }

  }
  }


  // hide header
  .header__hide {
    display: none;
    @media(max-width:1024px){
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding: 14px 10px;
      background: $baseWhite;
      .header__logo{
        display: flex;
      }
    }
    .header-hide__buttons {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;

    }

    .header-hide__market{
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .3s;
      margin-right:25px;
      .icon{
        width:30px;
        height: 30px;
        fill: transparent;
        transition: .3s;
        stroke: $baseBlue;
        stroke-width: 1.5px;

      }
      &:hover{
        .icon{
          stroke:$basePink ;
        }
      }
    }

    .header__burger{
      width: 25px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      transition: .3s;
      border: none;
      outline: none;
      padding: 0;
      margin: 0px;
      cursor: pointer;
      .icon{
        stroke: $basePink;
        stroke-width: 1.5px;
        transition: .3s;
        width: 25px;
        height: 20px;
      }
      &:hover{
        .icon{
          stroke: $baseHover;
        }
      }
    } 
  }
}
