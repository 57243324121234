.product{
  &-hero{
    // .breadcrumbs{
    //   margin-bottom: 50px;
    // }
    .hero__container{
      width: 100%;
      position: relative;
      background: $baseWhite;
      padding: 20px 60px;
      max-width: 1640px;
      margin: 0 auto;
      padding-top: 10px;
      @media(max-width:1450px){
        padding: 20px 35px;
        padding-top: 10px;
        max-width: 1590px;
      }
      @media(max-width:1024px){
        padding: 10px;
        padding-bottom: 20px;
      }
    }
  }
  &-mobile{
    &__title{
      display: none;
      @media(max-width:1024px){
        display: block;
        font-family: Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 32px;
        color: #252525;
        margin-bottom: 15px;
      }
    }
    &__subtitle{
      display: none;
      @media(max-width:1024px){
        display: block;
        font-family: Gilroy;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
        color: #434343;
        margin-bottom: 20px;
        span{
          display: inline-block;
          margin-left: 5px;
          font-weight: 500;
          color: $basePink;
        }
      }
    }
  }
  &-wrap{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    @media(max-width:1200px){
      align-items: flex-start;
    }
  }
  &-gallery{
    width: 50%;
    overflow: hidden;
    position: relative;
    @media(max-width:1024px){
      width: 100%;
      max-width: 740px;
      margin: 0 auto 15px;
    }
    .gallery-next,
    .gallery-prev{
      cursor: pointer;
      bottom: 42px;
      position: absolute;
      @media(max-width:1200px){
        bottom: 30px;
      }
      @media(max-width:1024px){
        z-index: 100;
        bottom: 50%;
        background: rgba($basePink, .5);
        padding: 18px 5px;
      }
      .icon{
        width: 12px;
        height: 42px;
        fill: transparent;
        stroke: $basePink;
        @media(max-width:1024px){
          stroke: $baseWhite;
          width: 9px;
          height: 17px;
          stroke-width: 3px;
        }
      }
    }
    .gallery-prev{
      left: 0;
    }
    .gallery-next{
      right: 0;
    }
    .swiper-slide{
      background: #F5F9FE;
      img{
        width: 100%;
      }
    }
    .productSwiperThumbs{
      width: 100%;
      margin-bottom: 24px;

      .swiper-slide{
        border-radius: 8px;
      }
      img{
        max-width: 740px;
      }
    }
    .productSwiper{
      position: relative;
      width: calc(100% - 74px);
      margin: 0 auto;
      overflow: hidden;
      @media(max-width:1024px){
        display: none;
      }
      .swiper-slide{
        border-radius: 6px;
        border: 1px solid transparent;
      }
      img{
        max-width: 148px;
      }
      .swiper-slide-thumb-active{
        border-color: $basePink;
      }
    }
  }
  &-desc{
    width: 50%;
    padding-left: 54px;
    @media(max-width:1200px){
      padding-left: 30px;
    }
    @media(max-width:1024px){
      width: 100%;
      padding-left: 0;
    }
    &__title{
      font-family: Gilroy;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 46px;
      color: #252525;
      margin-bottom: 15px;
      @media(max-width:1200px){
        font-size: 24px;
        line-height: 32px;
      }
      @media(max-width:1024px){
        display: none;
      }
    }
    &__info{
      font-family: Gilroy;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 18px;
      color: #434343;
      margin-bottom: 20px;
      span{
        display: inline-block;
        margin-left: 5px;
        font-weight: 500;
        color: $basePink;
      }
      @media(max-width:1024px){
        display: none;
      }
    }
    &__subtitle{
      font-family: Gilroy;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: #252525;
      margin-bottom: 10px;
    }
    &__size-list{
      a{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &__color-list,
    &__size-list{
      display: flex;
      li{
        background: #FFFFFF;
        transition: .3s;
        border: 1px solid #D0D8E4;
        box-sizing: border-box;
        border-radius: 2px;
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0 5px 5px 0;
        &.active{
          border-color: $basePink;
        }
        &:hover{
          border-color: rgba($basePink, .8);
        }
        &.disabled{
          &:hover{
            border-color: #D0D8E4;
          }
          a, span{
            opacity: 0.3;
            cursor: default;
          }
        }
      }
    }
    &__color-list{
      padding-bottom: 15px;
      margin-bottom: 20px;
      border-bottom: 1px solid #D0D8E4;
      a{
        width: 32px;
        height: 32px;
        border-radius: 2px;
      }
    }
    &__size-list{
      margin-bottom: 35px;
      a, span{
        font-family: Gilroy;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 21px;
        text-align: center;
        color: #828282;
      }
    }
    &__price{
      font-family: Gilroy;
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 46px;
      color: #252525;
      margin-bottom: 20px;
      @media(max-width:1200px){
        font-size: 22px;
        line-height: 28px;
      }
    }
    &__button{
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 50px;
      @media(max-width:1200px){
        margin-bottom: 30px;
        padding-bottom: 20px;
        position: relative;
      }
      @media(max-width:576px){
        padding-bottom: 0;
      }
    }
    &__count{
      .counter{
        background: #FFFFFF;
        padding: 16px 12px;
        border: 1px solid #D0D8E4;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        border-radius: 2px;
        &__input{
          width: 50px;
          border: 0;
          outline: none;
          margin: 0 10px;
          font-family: Gilroy;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 21px;
          text-align: center;
          color: #828282;
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button{
            /* display: none; <- Crashes Chrome on hover */
            -webkit-appearance: none;
            margin: 0;
          }
        }
        &__plus{
          &:after{
            position: absolute;
            content: '';
            background: #EE1D24;
            width: 1px;
            height: 100%;
            top: 50%;
            left: 0;
            right: 0;
            margin: -7px auto 0;
          }
        }
      }
      button{
        background: transparent;
        outline: none;
        border: 0;
        cursor: pointer;
        position: relative;
        display: inline-block;
        width: 15px;
        height: 15px;
        &:before{
          position: absolute;
          content: '';
          background: #EE1D24;
          width: 100%;
          height: 1px;
          top: 50%;
          margin-top: -1px;
          left: 0;
          border-radius: 1px;
        }
      }
    }
    &__add, &__one{
      button{
        border: 1px solid transparent;
        outline: none;
        width: 200px;
        cursor: pointer;
        padding: 20px;
        border-radius: 36px;
        font-family: Gilroy;
        font-style: normal;
        text-align: center;
        font-size: 15px;
        line-height: 18px;
        @media(max-width:1440px){
          width: 150px;
          padding: 15px 20px;
        }
      }
    }
    &__add{
      @media(max-width:576px){
        width: 100%;
        margin: 15px 0;
      }
      button{
        font-weight: bold;
        color: #FFFFFF;
        background: linear-gradient(90deg, #DC2C43 0%, #FF5369 100%);
        box-shadow: 0px 4px 4px rgba(233, 58, 81, 0.2);
        transition: all .4s ease-in-out;
        position: relative;
        z-index: 1;
        overflow: hidden ;
        &:after{
          width: 100%;
          height: 100%;
          content: "";
          background: linear-gradient(-90deg, #DC2C43 0%, #FF5369 100%);
          transition: .3s;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          opacity: 0;
        }
        &:hover{
          transition: all .4s ease-in-out;
          box-shadow: 0px 4px 4px rgba(233, 58, 81, 0.4);
        }
        &:hover::after{
          opacity: 1;
        }
      }
    }
    &__one{
      button{
        font-weight: 600;
        color: #DC2C43;
        outline: none;
        position: relative;
        background: transparent;
        border-color: $basePink;
        overflow: hidden;
        z-index: 1;
        transition: .3s;
        &:after{
          width: 100%;
          height: 100%;
          content: "";
          background: linear-gradient(-90deg, #DC2C43 0%, #FF5369 100%);
          transition: .3s;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          opacity: 0;
        }
        &:hover{
          color: $baseWhite;
          // background: linear-gradient(-90deg, #DC2C43 0%, #FF5369 100%);
        }
        &:hover::after{
          opacity: 1;
        }
      }
    }
    &__compare, &__wish{
      @media(max-width:1200px){
        position: absolute;
        top: 100%;
      }
      @media(max-width:576px){
        top: 42%;
      }
      cursor: pointer;
      .icon{
        width: 30px;
        transition: .3s;
        height: 30px;
        fill: transparent;
        stroke: #2C4378;
        stroke-width: 1.5px;
        @media(max-width:1200px){
          width: 25px;
          height: 25px;
        }
      }
      &:hover{
        .icon{
          stroke: $basePink;
        }
      }
    }
    &__compare {
      @media(max-width:1200px){
        left: 0;
      }
      @media(max-width:576px){
        left: auto;
        right: 60px;
      }
    }
    &__wish{
      @media(max-width:1200px){
        left: 60px;
      }
      @media(max-width:576px){
        left: auto;
        right: 0;
      }
    }
    &__text{
      display: flex;
      flex-wrap: wrap;
      border-top: 1px solid #D0D8E4;
      padding-top: 35px;
    }
    &__item{
      display: flex;
      width: 250px;
      @media(max-width:576px){
        margin-bottom: 20px;
      }
      &-media{
        margin-right: 16px;
      }
      &-desc{
        width: calc(100% - 52px);
      }
      .icon{
        fill: transparent;
        width: 35px;
        height: 36px;
        stroke: #DC2C43;
        stroke-width: 1.5px;
      }
      &-title{
        font-family: Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        color: #252525;
        margin-bottom: 5px;
      }
      &-list{
        li{
          font-family: Gilroy;
          font-weight: 300;
          font-size: 14px;
          line-height: 22px;
          color: #252525;
          padding-left: 20px;
          position: relative;
          &:before{
            position: absolute;
            content: '';
            left: 5px;
            top: 50%;
            margin-top: -2px;
            border-radius: 50%;
            width: 3px;
            height: 3px;
            background: #252525;
          }
        }
      }
    }
  }
}
