.cart {
  .breadcrumbs {
    padding-left: 0;
    margin-bottom: 60px;
  }
  &__hero {
    &-title {
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 48px;
      max-width: 100%;
      margin-bottom: 30px;
      position: relative;
      &:after {
        width: 140px;
        height: 4px;
        position: absolute;
        content: "";
        left: 0;
        top: 4px;
        transform: translateY(-30px);
        background: $basePink;
        border-radius: 3px;
      }
      @media(max-width: 992px){
        font-size: 26px;
        line-height: 32px;
      }
    }
  }
}
