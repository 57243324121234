.blog__content {
    width: 100%;
    position: relative;
    background: $baseWhite;
    padding:30px 60px;
    padding-bottom: 180px;
    @media(max-width:1450px){
        padding: 20px 35px;
        padding-bottom: 150px;
    }
    @media(max-width:1024px){
        padding: 20px 10px;
        padding-bottom:75px;
    }
    .blog__container {
        width: 100%;
        max-width: 1520px;
        margin: 0 auto;
        
    }

    .blog__title{
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 48px;
        color: $baseBlack;
        max-width: 100%;
        margin-bottom: 30px;
        position: relative;
        @media(max-width:1024px){
            font-size: 26px;
            line-height: 32px;
            margin-bottom: 25px;
            padding: 0;
        }

        &:after{
            width:140px ;
            height: 4px;
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            transform: translateY(-30px);
            background: $basePink;
            border-radius: 3px;
            @media(max-width:1024px){
                transform: translateY(-18px);
            }
        }
    }


    .blog__list {
        width: calc(100% + 44px);
        margin-left: -22px;
        display: flex;
        flex-wrap: wrap;
        @media(max-width:1024px){
            width: calc(100% + 20px);
            margin-left: -10px;
            
        }
    }



    .article__item  {
        width: 33.333333333%;
        padding: 0 22px;
        margin-bottom: 64px;
        @media(max-width:1280px){
            width: 50%;
        }
        @media(max-width:1024px){
            padding: 0 10px;
            margin-bottom: 40px;
        }
        @media(max-width:500px){
            width: 100%;
        }
    }
    .article__inner{
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        
    }

    .article__photo{
        display: flex;
        margin-bottom:25px;
        width: 100%;
        height: 260px;
        position: relative;
        @media(max-width:1024px){
            height: 160px;
            margin-bottom: 20px;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .article__name{
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: $baseBlack;
        margin-bottom: 21px;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        @media(max-width:1024px){
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 13px;
        }
        @media(max-width:580px){
            -webkit-line-clamp: 3;
        }
    }

    .article__info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .article__date{
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 14px;
        color: $baseBlack;
        opacity: .5;
        margin-right: 10px;
    }

    .article__time{
        display: flex;
        align-items: center;
        margin-left: 10px;
    }

    .time__icon {
        width: 19px;
        height: 19px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon{
           stroke: #919191; 
           fill: transparent;
           width: 19px;
           height: 19px;
        }
    }

    .time__value{
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 14px;
        color: $baseBlack;
        opacity: .5;
        
    }
    .product-main__pagination{
        margin-top: -10px;
    }
}