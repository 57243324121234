.thanks-page {
  .info {
    min-height: 100vh;
    &__wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 20px 10px;
      max-width: 1540px;
      margin: 0 auto;
      min-height: 100vh;
    }
    &__icon {
      img {
        width: 198px;
        height: 198px;
        @media(max-width: 768px){
          width: 120px;
          height: 120px;
        }
      }
      margin-bottom: 47px;
      @media(max-width: 768px){
        margin-bottom: 16px;
      }
    }
    &__title {
      font-weight: bold;
      font-size: 36px;
      line-height: 42px;
      text-align: center;
      margin-bottom: 15px;
      @media(max-width: 768px){
        font-size: 26px;
        line-height: 42px;
        margin-bottom: 5px;
      }
    }
    &__text {
      font-weight: 300;
      font-size: 18px;
      line-height: 36px;
      text-align: center;
      max-width: 513px;
      margin: 0 auto 25px;
      @media(max-width: 768px){
        font-size: 16px;
        line-height: 30px;
        margin-bottom: 30px;
      }
    }
    &__btn {
      display: inline-flex;
      min-height: 58px;
      background: linear-gradient(90deg, #DC2C43 0%, #FF5369 100%);
      box-shadow: 0px 4px 4px rgba(233, 58, 81, 0.2);
      border-radius: 36px;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      /* or 129% */
      min-width: 240px;
      text-align: center;
      align-items: center;
      justify-content: center;
      padding: 10px 15px;
      color: $baseWhite;
      transition: .3s;
      position: relative;
      overflow: hidden;
      z-index: 1;

      &:after{
        content: "";
        position: absolute;
        top: 0;
        z-index: -1;
        transition: .3s;
        opacity: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, #D21E35 0%, #EE3149 100%);
      }
      &:hover:after{
        opacity: 1;
      }
    }
  }
}
