.homepage__products {
    width: 100%;
    position: relative;
    padding: 80px 60px;
    background: $baseWhite;
    padding-bottom: 30px;
    @media(max-width:1450px){
        padding: 80px 35px;
        padding-bottom: 30px;
    }
    @media(max-width:1024px){
        padding: 45px 10px;
        padding-bottom: 30px;
    }
    .products__container {
        width: 100%;
        max-width: 1520px;
        margin: 0 auto;
    }

    .products__title{
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 48px;
        color: $baseBlack;
        max-width: 410px;
        margin-bottom: 30px;
        position: relative;

        &:after{
            width:140px ;
            height: 4px;
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            transform: translateY(-30px);
            background: $basePink;
            border-radius: 3px;
            @media(max-width:1024px){
                transform: translateY(-18px);
            }
        }
        @media(max-width:1024px){
            font-size: 26px;
            line-height: 32px;
            margin-bottom: 30px;
        }
    }

    .product__block {
        @media(min-width: 1025px){
            display: flex;
            flex-wrap: wrap;
            width: calc(100% + 30px);
            margin-left: -15px;
        }
        .swiper-slide{
            width: 25%;
            padding: 0 15px;
            margin-bottom:50px;
            @media(max-width:1350px){
                width: 33.333333333333333%;
            }
            @media(max-width:1024px){
                width: auto;
                display: flex;
                height: auto;
                padding: 0;
                margin: 0;
            }
        }
    }

    .product__slider{
        width: 100%;
        overflow: hidden;
        position: relative;

        .swiper-next{
            position: absolute;
            z-index: 2;
            top: 0;
            right: 0;
            display: none;
            outline: none;
            align-items: center;
            justify-content: center;
            transition: .3s;
            cursor: pointer;
            transform: translateY(-70px);
            .icon-arrow-slider{
                width: 46px;
                height: 18px;
                fill: $basePink;
                @media(max-width:1024px){
                    display: none;
    
                }
            }
    
            .icon-arrow{
                display: none;
                @media(max-width:1024px){
                    display: block;
                    width: 16px;
                    height: 8px;
                    fill: transparent;
                    transition: .3s;
                    stroke: $baseWhite;
                    stroke-width: 1.5px;
                    transform: rotate(90deg);
                }
            }
    
            @media(max-width:1250px){
                display: flex;
            }
            @media(max-width:1024px){
                background: rgba(220,44,67,0.5);
                width: 20px;
                height: 46px;
                display: flex;
                align-items: center;
                justify-content: center;
                transform: translateX(0) translateY(-50%);
                top: 50%;
            }
        }
    
    
        .swiper-prev{
            position: absolute;
            z-index: 2;
            top: 0;
            right: 0;
            display: none;
            align-items: center;
            justify-content: center;
            outline: none;
            transition: .3s;
            cursor: pointer;
            transform: translateY(-70px) translateX(-68px) scale(-1,1);
            .icon-arrow-slider{
                width: 46px;
                height: 18px;
                fill: $basePink;
                @media(max-width:1024px){
                    display: none;
    
                }
            }
    
            .icon-arrow{
                display: none;
                @media(max-width:1024px){
                    display: block;
                    width: 16px;
                    height: 8px;
                    fill: transparent;
                    transition: .3s;
                    stroke: $baseWhite;
                    stroke-width: 1.5px;
                    transform: rotate(-90deg);
                }
            }
            @media(max-width:1250px){
                display: flex;
            }
            @media(max-width:1024px){
                background: rgba(220,44,67,0.5);
                width: 20px;
                height: 46px;
                display: flex;
                align-items: center;
                justify-content: center;
                transform: translateX(0) translateY(-50%);
                top: 50%;
                right: auto;
                left: 0;
            }
        }

    }

    

    
}

// product item



.product__item {
    width: 25%;
    padding: 0 15px;
    margin-bottom:50px;
    @media(max-width:1350px){
        width: 33.333333333333333%;
    }
    @media(max-width:1024px){
        padding: 0 10px;
        margin-bottom:40px;
    }
    @media(max-width:1024px){
        width: 50%;
        
    }
    @media(max-width:500px){
        width: 100%;
    }
    &.diskount{
        .product__diskount-marker{
            display: flex;
        }
        .product-old__price{
            display: inline-flex;
        }
    }

    &.new-product{
        .card__top:before{
            content: "new";
            text-transform: uppercase;
            position: absolute;
            left: 10px;
            top: 10px;
            width: 42px;
            height: 42px;
            border-radius: 50%;
            background: #BB6BD9;
            color: $baseWhite;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: Gilroy;
            font-style: normal;
            font-weight: bold;
            font-size: 11px;
            line-height: 11px;
            z-index: 1;
        }
    }

    &.favorite{
        .follow-product__btn .icon{
            fill: $basePink;
        }
    }

    .product__card{
        width: 100%;
        height: 100%;
        position: relative;
    }
    
    .card__top {
        width: 100%;
        position: relative;
    
    }

    .product-card__photo{
        width: 100%;
        height: 320px;
        border-radius: 8px;
        background: $baseBack;
        overflow: hidden;
        margin-bottom: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        @media(max-width:1024px){
            height: 260px;
            margin-bottom: 20px;
        }

        img{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            max-width: 320px;
            max-height: 320px;
            object-fit: cover;
        }

    }

    .follow-product__btn {
        width: 36px;
        height: 36px;
        background: $baseWhite;
        outline: none;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s;
        position: absolute;
        top: 16px;
        right: 16px;
        border-radius: 50%;
        z-index: 1;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.03);
        cursor: pointer;
        .icon{
            width: 20px;
            height: 17px;
            stroke: $basePink;
            fill: transparent;
            transition: .3s;
        }
        @media(max-width:1024px){
            top: 13px;
            right: 13px;
        }

        &:hover{
            .icon{
                fill: $basePink;
            }
        }

        
    }

    .product__diskount-marker{
        width: 42px;
        height: 42px;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        background: $baseGreen;
        position: absolute;
        z-index: 1;
        left: 13px;
        top: 13px;
        display: none;
        .icon{
            width: 16px;
            height: 16px;
            fill: $baseWhite;
        }
    }

    .product-card__name{
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        color: $baseBlack;
        margin-bottom: 14px;
        width: 100%;
        display: inline-block;
    }

    .card__info {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        height: calc(100% - 352px);
        @media(max-width:1024px){
            height: calc(100% - 280px);
        }

    }
    .product-card__price {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    }

    .product-main__price{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.06em;
        color: #434343;
    }

    .product-old__price{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        margin-right: 6px;
        letter-spacing: 0.06em;
        color: $basePink;
        text-decoration-line: line-through;
        display: none;
    }

    .product__btn{
        display: inline-flex;
        min-height: 48px;
        background: linear-gradient(90deg, #DC2C43 0%, #FF5369 100%);
        box-shadow: 0px 4px 4px rgba(233, 58, 81, 0.2);
        border-radius: 36px;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        /* or 129% */
        min-width: 160px;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 10px 15px;
        color: $baseWhite;
        transition: .3s;
        position: relative;
        overflow: hidden;
        z-index: 1;

        &:after{
            content: "";
            position: absolute;
            top: 0;
            z-index: -1;
            transition: .3s;
            opacity: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, #D21E35 0%, #EE3149 100%);
        }
        &:hover:after{
            opacity: 1;
        }
    }
    .product__description{
        display: none;
    }
}


.products__field{
    &.row{
        @media(min-width:1025px){
            .product__item{
                width: 100%;
                margin-bottom: 30px;
            }
            .product__card{
                flex-direction: row;
                display: flex;
            }
            .card__top{
                max-width: 360px;
    
            }
            .product-card__photo{
                margin: 0;
            }
            .card__info{
                width: calc(100% - 360px);
                align-items: flex-start;
                display: inline-flex;
                height: auto;
                justify-content: flex-start;
                padding-left: 45px;
                @media(max-width:1450px){
                    padding-left: 25px;
                }
            }
            .product-card__name{
                max-width: 490px;
                text-align: left;
                margin-bottom: 10px;
                font-size: 24px;
                line-height: 30px;
            }
            .product__bottom{
                display: flex;
                align-items: center;
            }
            .product-card__price{
                margin-bottom: 0;
                margin-right: 40px;
            }
            .product__description{
                display: flex;
                width: 100%;
                margin-bottom: 33px;
                font-family: Gilroy;
                font-style: normal;
                font-weight: 300;
                font-size: 18px;
                line-height: 36px;
                color:#434343 ;
                @media(max-width:1300px){
                    line-height: 26px;
                    font-size: 16px;
                }
            }
        }
        
    }
}



