.menu {
  --color: #fff;
  width: 36px;
  height: 36px;
  padding: 0;
  margin: 0;
  outline: none;
  position: relative;
  border: none;
  background: none;
  cursor: pointer;
  -webkit-appearence: none;
  -webkit-tap-highlight-color: transparent;
}
.menu svg {
  width: 64px;
  height: 48px;
  top: -6px;
  left: -14px;
  stroke: var(--color);
  stroke-width: 4px;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  display: block;
  position: absolute;
}
.menu svg path {
  transition: stroke-dasharray var(--duration, 0.85s) var(--easing, ease) var(--delay, 0s), stroke-dashoffset var(--duration, 0.85s) var(--easing, ease) var(--delay, 0s);
  stroke-dasharray: var(--array-1, 26px) var(--array-2, 100px);
  stroke-dashoffset: var(--offset, 126px);
  transform: translateZ(0);
}
.menu svg path:nth-child(2) {
  --duration: .7s;
  --easing: ease-in;
  --offset: 100px;
  --array-2: 74px;
}
.menu svg path:nth-child(3) {
  --offset: 133px;
  --array-2: 107px;
}
.menu.active svg path {
  --offset: 57px;
}
.menu.active svg path:nth-child(1), .menu.active svg path:nth-child(3) {
  --delay: .15s;
  --easing: cubic-bezier(.2, .4, .2, 1.1);
}
.menu.active svg path:nth-child(2) {
  --duration: .4s;
  --offset: 2px;
  --array-1: 1px;
}
.menu.active svg path:nth-child(3) {
  --offset: 58px;
}

.default-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  border: 0;
  max-width: 160px;
  display: inline-flex;
  font-family: 'Gilroy', sans-serif;
  min-height: 48px;
  background: linear-gradient(90deg, #DC2C43 0%, #FF5369 100%);
  box-shadow: 0px 4px 4px rgba(233, 58, 81, 0.2);
  border-radius: 36px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  min-width: 160px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  color: $baseWhite;
  transition: .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &:after{
    content: "";
    position: absolute;
    top: 0;
    z-index: -1;
    transition: .3s;
    opacity: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #D21E35 0%, #EE3149 100%);
  }
  &:hover:after{
    opacity: 1;
  }
}
