.banners {
    width: 100%;
    position: relative;
    background: $baseWhite;
    padding: 80px 60px;
    @media(max-width:1450px){
        padding: 80px 35px;
    }
    @media(max-width:1024px){
        padding: 45px 10px;
        padding-top: 30px;
    }

    .banner__container{
        width: 100%;
        max-width: 1550px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        @media(max-width:1200px){
            flex-direction: column;
            align-items: center;

        }

    }
    .banner__item{
        width: 100%;
        max-width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;  
        padding: 0 15px;
        @media(max-width:1200px){
            max-width: 100%;
            margin-bottom: 40px;
            padding: 0;
            &:last-of-type{
                margin: 0;
            }

        }
        @media(max-width:1024px){
            margin-bottom: 20px;

        }
    }
    .banner__inner{
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;

        
    }

    .banner__img{
        width: 100%;
        max-width: 745px;
        max-height: 240px;
        object-fit: contain;
        border-radius: 16px;

    }
}