.cart {
  .container {
    max-width: 1640px;
    padding-left: 60px;
    padding-right: 60px;
    margin: 0 auto;
    @media(max-width: 1450px) {
      padding-left: 35px;
      padding-right: 35px;
      max-width: 1590px;
    }
    @media(max-width: 1024px) {
      padding-right: 10px;
      padding-left: 10px;
    }
  }

  &__container {
    margin-bottom: 40px;
  }
  &__main {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 212px;
    @media(max-width: 1024px){
      margin-bottom: 40px;
    }
  }
  &__form {
    width: (513 / 1520) * 100%;
    padding-right: 26px;
    @media(max-width: 1200px) {
      width: 100%;
      padding-right: 0;
      margin-bottom: 50px;
    }
  }
  &__info {
    width: (997 / 1520) * 100%;
    @media(max-width: 1200px) {
      order: -1;
      width: 100%;
      margin-bottom: 30px;
    }
  }
  &-info {
    max-width: 360px;
    margin-left: auto;
    @media(max-width: 1024px){
      max-width: 100%;
    }
    &.hidden {
      display: none;
    }
    &__delivery {
      &.hidden {
        display: none;
      }
    }
    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }
    &__btn {
      display: flex;
      font-size: 15px;
      margin-top: 35px;
      margin-left: auto;
      min-height: 58px;
      min-width: 200px;
      @media(max-width: 1024px){
       display: none;
      }
      &_form {
        @media(max-width: 1024px){
          margin-left: 0;
          display: block;
        }
        @media(min-width: 1024px){
          display: none;
        }
      }
    }
    &__text {
      font-size: 20px;
      text-align: right;
      @media(max-width: 1024px){
        font-size: 16px;
        text-align: left;
      }
    }
    &__figures {
      width: 117px;
      padding-left: 5px;
      font-weight: bold;
      font-size: 20px;
      text-align: right;
      margin-left: auto;
      span:not(span:last-child) {
        margin-right: 5px;
      }
      @media(max-width: 1024px){
        font-size: 16px;
      }
    }
  }
  &-content{
    background: $baseWhite;
    position: relative;

    .order__block {
      width: 100%;
      position: relative;
      margin-bottom: 50px;
    }

    .cart__values {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 14px 95px 14px 25px ;
      background: $lightGray;
      border-radius: 6px;
      @media(max-width: 1024px){
        display: none;
      }
    }

    .cart__value{
      font-family: Gilroy, serif;
      font-weight: bold;
      line-height: 32px;
      color: $baseBlack;
      padding-right: 10px;
      font-size: 15px;
      line-height: 30px;
      @media(max-width:1450px){
        font-size: 14px;
        line-height: 30px;
      }
      @media(max-width:1024px){
        line-height: 26px;
      }
      &:nth-child(1){
        max-width:145px;
        min-width: 145px;
        @media(max-width:1250px){
          max-width: 130px;
          min-width: 130px;
        }
      }

      &:nth-child(2){
        width: 22%;
      }
      &:nth-child(3){
        width: 12.2%;
      }
      &:nth-child(4){
        width: 10.4%;
        min-width: 100px;
      }
      &:nth-child(5){
        width: 7%;
        min-width: 50px;
      }
      &:nth-child(6){
        width: 7%;
        min-width: 60px;
      }
      &:nth-child(7){
        width: 13.2%;
      }
      &:nth-child(8){
        width: 10.6%;
      }
    }

    .cart__items {
      width: 100%;
      position: relative;
    }
    .cart__item{
      position: relative;
      display: flex;
      justify-content: space-between;
      padding: 22px 0 22px 25px;
      border-bottom: 1px solid $baseGrey;
      @media(max-width: 1024px){
        margin-bottom: 30px;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 0 10px;
        &:last-of-type{
          margin-bottom: 0;
        }
      }
      &-price {
        font-weight: 300;
        margin-right: 3px;
      }
      &-sum {
        margin-right: 3px;
      }
      &-btn {
        margin-top: 13px;
        margin-right: 27px;
        cursor: pointer;
        width: 20px;
        height: 20px;
        transition: all .3s ease;
        filter: grayscale(100%);
        opacity: .4;
        &:hover {
          filter: grayscale(0);
          opacity: 1;
        }
        @media(max-width: 1024px){
          position: absolute;
          left: 160px;
          top: 0;
          margin: 0;
        }
      }
    }

    .item__inner{
      display: flex;
      //align-items: center;
      width: calc(100% - 95px);
      @media(max-width: 1024px){
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
      }
    }

    .cart__option{
      padding-top: 13px;
      padding-right: 0;
      &:first-child {
        padding-top: 0;
      }
      @media(max-width: 1024px){
        line-height: 24px;
        margin-bottom: 10px;
        padding: 0;
        &:last-of-type{
          margin-bottom: 20px;
        }
      }
      .cart__text{
        font-weight: 300;
        line-height: 22px;
        color: $baseBlack;
        font-size: 15px;

        @media(max-width:1450px){
          font-size: 13px;
          line-height: 16px;
        }
        @media(max-width:1250px){
          font-size: 12px;
          line-height: 16px;
        }
        @media(max-width:1024px){
          font-size: 16px;
          line-height: 24px;
        }
      }

      .cart__color-item{
        width: 22px;
        height: 22px;
      }

      .cart__value{
        display: none;
        @media(max-width: 1024px){
          display: block;
          font-size: 16px;
          font-weight: bold;
          line-height: 32px;
          color: $baseBlack;
          padding: 0;
          max-width: 100%;
          min-width: auto;
        }
      }

      &:nth-child(1){
        width: 100%;
        max-width:145px;
        min-width: 145px;
        @media(max-width:1250px){
          max-width: 130px;
          min-width: 130px;
        }
        @media(max-width: 1024px){
          max-width: 100%;
          min-width: auto;
          margin-bottom: 25px;
        }
      }

      &:nth-child(2){
        max-width: 348px;
        width: 22%;
        padding-right: 10px;
        @media(max-width: 1024px){
          width: 100%;
          padding-right: 0;
          max-width: 100%;
        }
      }
      &:nth-child(3){
        width: 12.2%;
        padding-right: 10px;
        @media(max-width: 1024px){
          width: 100%;
          padding-right: 0;
          max-width: 100%;
        }
      }
      &:nth-child(4){
        width: 10.4%;
        min-width: 100px;
        @media(max-width: 1024px){
          width: 100%;
          max-width: 100%;
        }
      }
      &:nth-child(5){
        width: 7%;
        min-width: 50px;
        @media(max-width: 1024px){
          width: 100%;
          max-width: 100%;
        }
      }
      &:nth-child(6){
        width: 7%;
        min-width: 60px;
        @media(max-width: 1024px){
          width: 100%;
          max-width: 100%;
        }
      }
      &:nth-child(7){
        width: 13.2%;
        @media(max-width: 1024px){
          width: 100%;
          max-width: 100%;
        }
      }
      &:nth-child(8){
        width: 10.6%;
        @media(max-width: 1024px){
          width: 100%;
          max-width: 100%;
        }
      }
    }

    .cart__photo{
      width: 130px;
      height: 116px;
      background: $baseBack;
      border-radius: 8px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      @media(max-width:1250px){
        width: 110px;
        height: 110px;
      }
      @media(max-width: 1024px){
        width: 130px;
        height: 116px;
      }
      img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
    }
  }
  .count {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 8px;
    width: 80px;
    height: 42px;
    border: 1px solid $baseGrey;
    border-radius: 2px;
    &__input {
      border: 0;
      font-size: 13px;
      text-align: center;
      width: 30px;
      color: $secondaryGray;
    }
    &__btn {
      cursor: pointer;
      padding: 4px;
      .icon {
        fill: $basePink;
      }
      &_minus {
        .icon-minus {
          width: 11.85px;
          height: 5px;
        }
      }
      &_plus {
        .icon-count-plus {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
}
