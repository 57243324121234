.brands-detail-page{
  .homepage__diskount{
    padding-bottom: 180px;
    padding-top: 120px;
    @media(max-width:1024px){
      padding-top: 45px;
      padding-bottom: 75px;
    }
  }
} 

.reviews-wrapper {
  display: flex;
  position: relative;
  background: $baseWhite;
  max-width: 1640px;
  margin: 0 auto;
  padding: 50px 60px;
  padding-bottom: 70px;
  @media(max-width: 1450px) {
    padding: 50px 35px;
    padding-bottom: 70px;
    max-width: 1590px;
  }
  @media(max-width: 1024px) {
    padding: 30px 10px;
    padding-bottom: 45px;
    flex-wrap: wrap;
  }

  .reviews-item__answer{
    align-items: center;
    display: flex;
    button{
      cursor: pointer;
      transition: .3s;
      outline: none;
      border: none;
      &:hover{
        color: $baseHover;
      }  
    }
  }

  .product-main__pagination{
    margin-top: 40px;
    @media(max-width:1024px){
      margin-top: 0;
    }
  }



  
.reviews__title {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
  color: $baseBlack;
  max-width: 100%;
  margin-bottom: 20px;
  position: relative;
  @media(max-width: 1024px) {
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 25px;
  }

  &:after {
    width: 140px;
    height: 4px;
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    transform: translateY(-30px);
    background: $basePink;
    border-radius: 3px;
    @media(max-width:1024px){
      transform: translateY(-18px);
  }
  }
}

.reviews__wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}
.reviews-item__plus{
  margin-bottom: 14px;
}

.reviews-item {
  width: calc(50% - 30px);
  margin: 0 15px;
  background: #FFFFFF;
  border: 1px solid #D0D8E4;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 30px 34px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-family: Gilroy;
  font-style: normal;
  @media screen and (max-width: 1024px){
    width: calc(100% - 30px);
    margin-bottom: 20px;
  }
  @media screen and (max-width: 576px){
    padding: 15px;
  }
  &__media {
    width: 76px;
    height: 76px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: inherit;
    }
  }

  &__info {
    width: calc(100% - 76px);
    padding-left: 18px;
  }

  &__name {
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    color: #252525;
    margin-bottom: 5px;
  }

  &__date {
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #434343;
  }

  &__desc {
    padding-top: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__title {
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    color: #252525;
    margin-bottom: 10px;
    width: 100%;
  }

  &__title-name {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #DC2C43;
    margin-bottom: 10px;

    a {
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #DC2C43;

      &:hover {
        color: rgba($basePink, .8);
      }
    }
  }

  &__text {
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    color: #434343;
    margin-bottom: 16px;
  }

  &__plus, &__minus {
    position: relative;
    padding-left: 33px;
    width: 100%;

    &:before {
      position: absolute;
      content: '';
      width: 18px;
      height: 2px;
      left: 0;
      border-radius: 2px;
    }

    strong {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      display: block;
      margin-bottom: 8px;
    }

    span {
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__plus {
    &:before {
      background: $baseGreen;
      top: 10px;
      margin-top: -1px;
    }

    &:after {
      position: absolute;
      content: '';
      width: 2px;
      height: 18px;
      left: 8px;
      top: 1px;
      border-radius: 2px;
      background: $baseGreen;
    }
  }

  &__minus {
    margin-bottom: 20px;
    &:before {
      background: $basePink;
      top: 10px;
      margin-top: -1px;
    }
  }

  &__answer {
    width: 50%;
    @media screen and (max-width: 1220px){
      width: 100%;
      margin-bottom: 20px;
    }
    button {
      background: transparent;
      border: 0;
      font-weight: 600;
      font-size: 15px;
      line-height: 16px;
      color: $basePink;
    }
  }

  &__like-block {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media screen and (max-width: 1220px){
      width: 100%;
      justify-content: flex-start;
    }
  }

  &__like-title {
    color: $baseBlack;
    margin-right: 10px;
  }

  &__like-positive, &__like-negative {
    display: flex;
    align-items: center;
    span {
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 14px;
    }
    .icon {
      width: 26px;
      height: 26px;
      margin-right: 5px;
    }
  }

  &__like-positive {
    margin-right: 15px;
    span {}
    .icon {
      margin-bottom: 6px;
      fill: $baseGreen;
    }
  }

  &__like-negative {
    span {}
    .icon {
      margin-top: 6px;
      fill: $basePink;
    }
  }
}
}
