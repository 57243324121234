#register-modal {
  .modal__title {
    margin-bottom: 18px;
  }
  .form {
    &-info {
      margin-bottom: 0;
      &__details {
        margin-bottom: 20px;
      }
      &__text-wrap {
        @media(max-width: 568px){
          width: 100%;
        }
      }
      &__bottom {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        .default-btn {
          min-width: 200px;
          @media(max-width: 568px){
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
