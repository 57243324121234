.balance-content{
    width: 100%;
    padding: 30px 60px;
    background: $baseWhite;
    position: relative;
    padding-bottom: 200px;
    @media(max-width:1450px){
        padding: 30px 35px;
        padding-bottom: 150px;

    }
    @media(max-width:1024px){
        padding: 30px 10px;
        padding-bottom: 75px;
    }
    .balance__container {
        width: 100%;
        position: relative;
        max-width: 1520px;
        margin: 0 auto;

    }

    .balance__block {
        display: flex;
        align-items: center;
        margin-bottom: 35px;
        @media(max-width:1024px){
            margin-bottom: 17px;
            flex-direction: column;
            align-items: flex-start;


        }
    }

    .balance__placeholder{
        font-family: Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 32px;
        color: $baseBlack;
        margin-right: 30px;
        @media(max-width:1024px){
            margin-right: 0;
        }
    }

    .balance__value {
        font-family: Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 32px;
        color: $baseBlack;

    }

    .balance__btn{
        display: inline-flex;
        min-height: 48px;
        background: linear-gradient(90deg, #DC2C43 0%, #FF5369 100%);
        box-shadow: 0px 4px 4px rgba(233, 58, 81, 0.2);
        border-radius: 36px;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        /* or 129% */
        min-width: 160px;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 10px 15px;
        color: $baseWhite;
        transition: .3s;
        position: relative;
        overflow: hidden;
        z-index: 1;
        outline: none;
        border: none;
        cursor: pointer;

        &:after{
            content: "";
            position: absolute;
            top: 0;
            z-index: -1;
            transition: .3s;
            opacity: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, #D21E35 0%, #EE3149 100%);
        }
        &:hover:after{
            opacity: 1;
        }
    }
}