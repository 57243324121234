.error-content {
    width: 100%;
    position: relative;
    background: $baseWhite;
    padding: 30px 60px;
    padding-bottom: 95px;
    @media(max-width:1450px){
        padding: 30px 35px;
        padding-bottom: 95px;

    }
    @media(max-width:1024px){
        padding: 20px 10px;
        padding-bottom: 45px;
    }

    .error__container{
        width: 100%;
        max-width: 1520px;
        margin: auto;

    }

    .error__title{
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 48px;
        color: $baseBlack;
        max-width: 100%;
        margin-bottom: 10px;
        position: relative;
        max-width: 1000px;
        @media(max-width:1024px){
            font-size: 26px;
            line-height: 32px;
            margin-bottom: 9px;
            padding: 0;
        }
        &:after{
            width:140px ;
            height: 4px;
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            transform: translateY(-30px);
            background: $basePink;
            border-radius: 3px;
            @media(max-width:1024px){
                transform: translateY(-18px);
            }
        }
    }

    .error__subtitle{
        color: $baseBlack;
        font-family: Gilroy;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 36px;
        margin-bottom: 20px;
        @media(max-width:1024px){
            font-size: 16px;
            line-height: 30px;
            
        }
    }

    .error__btn{
        display: inline-flex;
        min-height: 58px;
        background: linear-gradient(90deg, #DC2C43 0%, #FF5369 100%);
        box-shadow: 0px 4px 4px rgba(233, 58, 81, 0.2);
        border-radius: 36px;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        /* or 129% */
        min-width: 200px;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 10px 15px;
        color: $baseWhite;
        transition: .3s;
        position: relative;
        overflow: hidden;
        z-index: 1;

        &:after{
            content: "";
            position: absolute;
            top: 0;
            z-index: -1;
            transition: .3s;
            opacity: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, #D21E35 0%, #EE3149 100%);
        }
        &:hover:after{
            opacity: 1;
        }
    }
}