#restore-password {
  .modal {
    &__title {
      margin-bottom: 10px;
    }
    &__text {
      margin-bottom: 16px;
      .bold {
        margin-left: 5px;
        font-weight: bold;
      }
    }
    &__btn {
      min-width: 200px;
    }
  }
  .form-info {
    @media(max-width: 568px){
      span {
        display: block;
        margin-bottom: 2px;
      }
    }
  }
}
