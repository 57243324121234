.news__content {
    width: 100%;
    position: relative;
    background: $baseWhite;
    padding:30px 60px;
    padding-bottom: 180px;
    @media(max-width:1450px){
        padding: 20px 35px;
        padding-bottom: 150px;
    }
    @media(max-width:1024px){
        padding: 20px 10px;
        padding-bottom:75px;
    }
    .news__container {
        width: 100%;
        max-width: 1520px;
        margin: 0 auto;
        
    }

    .news__title{
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 48px;
        color: $baseBlack;
        max-width: 100%;
        margin-bottom: 30px;
        position: relative;
        @media(max-width:1024px){
            font-size: 26px;
            line-height: 32px;
            margin-bottom: 25px;
            padding: 0;
        }

        &:after{
            width:140px ;
            height: 4px;
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            transform: translateY(-30px);
            background: $basePink;
            border-radius: 3px;
            @media(max-width:1024px){
                transform: translateY(-18px);
            }
        }
    }


    .news__list {
        width: calc(100% + 30px);
        margin-left: -15px;
        display: flex;
        flex-wrap: wrap;
        @media(max-width:1024px){
            width: calc(100% + 20px);
            margin-left: -10px;
            
        }
    }


    .news__item{
        width: 25%;
        padding: 0 15px;
        margin-bottom: 60px;
        @media(max-width:1450px){
            width: 33.33333333%;
        }
        @media(max-width:1280px){
            width: 50%;
        }
        @media(max-width:1024px){
            padding: 0 10px;
            margin-bottom: 30px;
        }
        @media(max-width:500px){
            width: 100%;
        }
    }
    .item__inner{
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        
    }

    .news__photo{
        display: flex;
        margin-bottom:25px;
        width: 100%;
        height: 260px;
        position: relative;
        @media(max-width:1024px){
            height: 160px     ;
            margin-bottom: 20px;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .news__date{
        font-family: Gilroy;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 14px;
        color: $baseBlack;
        opacity: 0.5;
        margin-bottom: 12px;

    }

    .news__name{
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;
        color: $baseBlack;
        margin-bottom: 12 px;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        @media(max-width:1024px){
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 5px;
        }
        @media(max-width:580px){
            -webkit-line-clamp: 3;
        }
    }

    .news__subtext{
        font-family: Gilroy;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 30px;
        color: $baseBlack;
    }





    .product-main__pagination{
        margin-top: -10px;
        @media(max-width:1024px){
            margin-top: 0;
        }
    }
}