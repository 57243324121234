.footer {
  width: 100%;
  &__wrap {
    flex: 0 0 auto;
    width: 100%;
    background: $baseBlue;
    padding: 80px 60px;
    padding-bottom: 38px;
    position: relative;
    @media(max-width:1450px){
      padding-left: 35px;
      padding-right: 35px;
    }
    @media(max-width:1300px){
      padding: 35px;
    }
    @media(max-width:1024px){
      padding: 40px 0;
      padding-bottom: 25px;
    }
  }

  .footer_container{
    width: 100%;
    max-width:1800px;
    margin: 0 auto;
  }

  .footer__inner{
    width: 100%;
    max-width: 1550px;
    margin: 0 auto;
    padding-bottom: 50px;
    display: flex;
    justify-content: space-between;
    @media(max-width:1300px){
      flex-direction: column;
      align-items: center;
      padding-bottom: 35px;
    }
    @media(max-width:1024px){
      padding-bottom: 0;
    }
  }

  // footer copyright
  .footer__copy {
    width: 100%;
    border-top:1px solid $baseLightGrey ;
    padding-top: 35px;
    @media(max-width:1024px){
      padding-top: 25px;
      padding-left: 10px;
      padding-right: 10px;

    }
  }

  .copyright__text{
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: $baseWhite;
    @media(max-width:1024px){
      font-size: 12px;
    }
  }
}


// back to top


.back-to-top {
  width: 40px;
  height: 40px;
  position: fixed;
  z-index: 11;
  bottom: 80px;
  right: 80px;
  transition: .3s;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: $basePink;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  .icon{
    width:17px;
    height: 9px;
    fill: transparent;
    stroke-width: 2px;
    stroke:$baseWhite;
    transition: .3s;
  }
  @media(max-width:1450px){
    right: 35px;
    bottom: 35px;
  }
  @media(max-width:1024px){
    right: 15px;
    bottom: 53px;
    width: 33px;
    height: 33px;
    .icon{
      width: 15px;
      height: 8px;
    }
  }

  &:hover{
    background: $baseHover;
  }
}
.footer__adds {
  display: none;
  @media(max-width:1024px){
    display: flex;
    flex-direction: column;
    align-items: center;
    .footer__pay{
      display: flex !important;
    }
    .footer__delivery{
      display: flex !important;
    }
  }
}
// footer media
.footer__media, .footer__adds{
  width: 100%;
  max-width: 270px;
  position: relative;
  @media(max-width:1300px){
    display: flex;
    max-width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
  }
  @media(max-width:1024px){
    flex-direction: column;
    margin-bottom: 22px;
    padding: 0 10px;
  }

  .footer__logo{
    width: 270px;
    height: 75px;
    display: block;
    margin-bottom: 28px;
    @media(max-width:1300px){
      margin: 0;
      margin-right: 15px;
    }
    @media(max-width:1024px){
      margin-right: 0;
      margin-bottom: 20px;
      width: 226px;
      height: 63px;
    }
    img{
      width: 100%;
      height: 100%;
    }
  }

  // social medias
  .footer__social {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 25px;
    @media(max-width:1300px){
      display: inline-flex;
      width: auto;
      margin-right: 15px;
      margin-bottom: 0;
    }
    @media(max-width:1024px){
      margin: 0;
    }
  }

  .social__item{
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    border: 1px solid $baseWhite;
    border-radius: 50%;
    margin-bottom: 5px;
    transition: .3s;

    &:last-of-type{
      margin-right: 0;
    }

    &:hover{
      opacity: .4;
    }

    .icon{
      transition: .3s;
      fill: $baseWhite;
      max-width: 17px;
      max-height: 17px;
    }

    .icon-facebook{
      width: 8px;
      height: 15px;
    }

    .icon-instagram{
      width: 14px;
      height: 14px;
    }

    .icon-youtube{
      width: 16px;
      height: 11px;

    }
  }

  // pay types
  .footer__pay{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 17px;

    @media(max-width:1300px){
      display: inline-flex;
      width: auto;
      margin-right: 15px;
      margin-bottom: 0;
    }
    @media(max-width:1024px){
      display: none;
    }

  }

  .pay__item{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 32px;
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: 3px;
    background: #00579F;
    &:last-of-type{
      margin-right: 0;
    }

    &:nth-child(4n-2){
      background: #0F3560;
    }

    &:nth-child(4n-1){
      background: #5485EC;
    }

    &:nth-child(4n){
      background: #888888;
    }

    
  }

  .pay__img{
    max-width: 38px;
    width: 100%;
    object-fit: contain;
  }

  // delivery types

  .footer__delivery{
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @media(max-width:1300px){
      display: inline-flex;
      width: auto;
    }
    @media(max-width:1024px){
      display: none;
    }

  }

  .delivery__item {
    margin-right: 20px;
    margin-bottom: 10px;
    &:last-of-type{
      margin-right: 0;
    }

    .icon{
      fill: $baseWhite;
    }

    .icon-np{
      width: 90px;
      height: 32px;
    }

    .icon-justin{
      width: 72px;
      height: 32px;
    }
  }

  

}


.footer__adds {
  display: none;
  @media(max-width:1024px){
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0 !important;
    padding: 30px 10px;
    .footer__pay{
      display: flex !important;
      margin-bottom: 12px;
      margin-right: 0;
    }
    .footer__delivery{
      display: flex !important;
    }
  }
}
// footer info
.footer__info {
  width: 100%;
  max-width: 1200px;
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
  @media(max-width:1300px){
    max-width: 100%;
  }
  @media(max-width:1024px){
    flex-direction: column;
    margin: 0;
  }


  .footer__column {
    width: 100%;
    max-width: 195px;
    margin-right: 20px;
    &.active{
      .info-column__title .icon{
        transform: scale(1,-1) rotate(180deg);
      }
    }
    @media(max-width:1450px){
      margin-right: 15px;
    }
    @media(max-width:1024px){
      max-width: 100%;
      margin: 0;
      border-top: 1px solid $baseLightGrey;
    }
    &:last-of-type{
      margin-right: 0;
      @media(max-width:1024px){
        border-bottom: 1px solid $baseLightGrey;
      }
    }

    &.big-column{
      max-width: 400px;
      @media(max-width:1024px){
        max-width: 100%;
      }
      li{
        width: 50%;
        @media(max-width:1024px){
          width: 100%;
          padding: 0;
        }
        &:nth-child(2n-1){
          padding-right: 25px;
          @media(max-width:1450px){
            padding-right: 7.5px;
          }
          @media(max-width:1024px){
            width: 100%;
            padding: 0;
          }
          
        }
        &:nth-child(2n){
          padding-left: 25px;
          @media(max-width:1450px){
            padding-left: 7.5px;
          }
          @media(max-width:1024px){
            width: 100%;
            padding: 0;
          }

        }
      }
    }

  }

  .info-column__title{
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    color: $baseWhite;
    margin-bottom: 15px;

    
    .icon{
      display: none;
      @media(max-width:1024px){
        display: flex;
        width: 16px;
        transition: .3s;
        height: 8px;
        stroke-width: 1.5px;
        transform: scale(1,-1);
        fill: transparent;
        stroke: $baseWhite;
      }
    }
    @media(max-width:1024px){
      margin-bottom: 0;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      padding: 15px 10px;
      cursor: pointer;
    }

    
  }

  .info__links {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    @media(max-width:1024px){
      display: none;
      padding: 10px;
      padding-bottom: 20px;
    }
    li{
      width: 100%;
      line-height: 100%;
      margin-bottom: 12px;
      @media(max-width:1024px){
        display: flex;
        align-items: center;
        justify-content: center;
        &:last-of-type{
          margin-bottom: 0;
        }
      }
    }
    
  }
  .footer__link{
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    transition: .3s;
    color: $baseWhite;
    @media(max-width:1450px){
      font-size: 13px;
      line-height: 18px;
    }
    @media(max-width:1024px){
      font-size: 14px;
      
    }

    &:hover{
      opacity: .4;
    }
    
  }

  .footer__contact-info{
    width: 100%;
    position: relative;
    @media(max-width:1024px){
      display: flex;
      flex-direction: column;
      align-items: center;
    }

  }

  .footer-contact__item{
    width: 100%;
    display: flex;
    margin-bottom: 15px;
    &:last-of-type{
      margin-bottom: 0;
    }
    @media(max-width:1024px){
      max-width: 190px;
    }

  }

  .item__icon{
    width: 20px;
    height: 20px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon{
      width: 20px;
      height: 20px;
      fill: transparent;
      stroke: $baseWhite;
    }
  }

  .item__info{
    width: 100%;
    max-width: calc(100% - 30px);

    p,a{
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      color: $baseWhite;
      transition: .3s;
      @media(max-width:1450px){
        font-size: 13px;
        line-height: 18px;
      }
      @media(max-width:1024px){
        font-size: 14px;
        line-height: 20px;
      }

    }
    a{
      width: 100%;
      float: left;
      clear: left;
    }
    
  }
  .footer-contact__btn{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $baseWhite;
    min-width: 160px;
    padding: 14px;
    min-height: 50px;
    border:none;
    outline: none;
    cursor: pointer;
    background: linear-gradient(90deg, #DC2C43 0%, #FF5369 100%);
    box-shadow: 0px 4px 4px rgba(233, 58, 81, 0.2);
    border-radius: 36px;
    margin-top: 26px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    @media(max-width:1024px){
      margin-left: 50%;
      transform: translateX(-50%);
    }



    &:after{
        content: "";
        position: absolute;
        top: 0;
        z-index: -1;
        transition: .3s;
        opacity: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, #D21E35 0%, #EE3149 100%);
    }
    &:hover:after{
        opacity: 1;
    }
  }


}


// mobile bottom menu

.menu-bottom__mobile {
  position: fixed;
  z-index: 30;
  width: 100%;
  left: 0;
  bottom: 0;
  background: $baseWhite;
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: none;
  @media(max-width:1024px){
    display: flex;
  }

  .menu-bottom__link{
    min-height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 20%;
    padding: 10px 19px;
    .icon{
      width: 24px;
      height: 24px;
      fill: transparent;
      stroke: $baseBlue;
      transition: .3s;
      stroke-width: 1.5px;
    }
    cursor: pointer;
    background: transparent;
    transition: .3s;
    outline: none;
    border: none;
    border-right: 1px solid #D0D8E4;
    
    &:last-child{
      border-right: none;
    }

    &:hover{
      .icon{
        stroke:$basePink ;
      }
    }
  }
}

