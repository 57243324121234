.homepage__diskount {
    width: 100%;
    position: relative;
    background: $baseWhite;
    padding: 60px 80px;
    @media(max-width:1450px){
        padding: 80px 35px;
    }
    @media(max-width:1024px){
        padding: 45px 10px;

    }
    .diskount__container{
        width: 100%;
        max-width: 1520px;
        margin: 0 auto;
    }

    .diskount__title{
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 48px;
        color: $baseBlack;
        max-width: 100%;
        margin-bottom: 40px;
        position: relative;
        padding-right: 120px;
        @media(max-width:1024px){
            font-size: 26px;
            line-height: 32px;
            margin-bottom: 30px;
            padding: 0;
        }

        &:after{
            width:140px ;
            height: 4px;
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            transform: translateY(-30px);
            background: $basePink;
            border-radius: 3px;
            @media(max-width:1024px){
                transform: translateY(-18px);
            }
        }
    }

    .diskount__slider{
        width: 100%;
        position: relative;

    }

    .swiper-next{
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        display: flex;
        outline: none;
        align-items: center;
        justify-content: center;
        transition: .3s;
        cursor: pointer;
        transform: translateY(-70px);
        .icon-arrow-slider{
            width: 46px;
            height: 18px;
            fill: $basePink;
            @media(max-width:1024px){
                display: none;

            }
        }

        .icon-arrow{
            display: none;
            @media(max-width:1024px){
                display: block;
                width: 16px;
                height: 8px;
                fill: transparent;
                transition: .3s;
                stroke: $baseWhite;
                stroke-width: 1.5px;
                transform: rotate(90deg);
            }
        }

        @media(max-width:1250px){
            display: flex;
        }
        @media(max-width:1024px){
            background: rgba(220,44,67,0.5);
            width: 20px;
            height: 46px;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateX(0) translateY(-50%);
            top: 50%;
        }
    }


    .swiper-prev{
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s;
        cursor: pointer;
        transform: translateY(-70px) translateX(-68px) scale(-1,1);
        .icon-arrow-slider{
            width: 46px;
            height: 18px;
            fill: $basePink;
            @media(max-width:1024px){
                display: none;

            }
        }

        .icon-arrow{
            display: none;
            @media(max-width:1024px){
                display: block;
                width: 16px;
                height: 8px;
                fill: transparent;
                transition: .3s;
                stroke: $baseWhite;
                stroke-width: 1.5px;
                transform: rotate(-90deg);
            }
        }
        @media(max-width:1250px){
            display: flex;
        }
        @media(max-width:1024px){
            background: rgba(220,44,67,0.5);
            width: 20px;
            height: 46px;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateX(0) translateY(-50%);
            top: 50%;
            right: auto;
            left: 0;
        }
    }

}

.swiper-button-disabled{
    opacity: .4;
    pointer-events: none;
}

.swiper-slide{
    display: flex;
    height: auto;
    .product__item{
        width: 100%;
        padding: 0;
        margin: 0;
    }
}