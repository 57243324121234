.homepage-about {
    width: 100%;
    background: $baseWhite;
    position: relative;
    padding: 80px 60px;
    padding-bottom: 100px;
    @media(max-width:1450px){
        padding: 80px 35px;
    }
    @media(max-width:1024px){
        padding: 45px 10px;
        padding-bottom: 55px;
    }


    .about__container {
        width: 100%;
        max-width: 1520px;
        margin: 0 auto;
        position: relative;
    }
}


// homepage about

.homepage-about__block {
    width: 100%;
    position: relative;
    margin-bottom: 60px;
    @media(max-width:1024px){
        margin-bottom: 40px;
    }

    .about__title{
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 48px;
        color: $baseBlack;
        max-width: 910px;
        margin-bottom: 40px;
        position: relative;
        @media(max-width:1024px){
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 25px;
            max-width: 600px;
        }

        &:after{
            width:140px ;
            height: 4px;
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            transform: translateY(-30px);
            background: $basePink;
            border-radius: 3px;
            @media(max-width:1024px){
                transform: translateY(-18px);
            }
        }

    }

    .about__inner{
        width: 100%;
        display: flex;
        justify-content: space-between;
        @media(max-width:1200px){
            flex-direction: column;
            align-items: center;
        }
    }

    .about__photo {
        width: 50%;
        max-width: 790px;
        display: flex;
        align-items: flex-start;
        // @media(max-width:1450px){
        //     width: 55%;
        // }
        @media(max-width:1200px){
            width: 100%;
            max-width:700px ;
            margin-bottom: 40px;
        }
        @media(max-width:1024px){
            margin-bottom: 25px;
        }
        @media(max-width:768px){
            max-width: 100%;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: contain;

        }
    }
    .about__info {
        width: 50%;
        padding-left: 60px;
        @media(max-width:1450px){
            padding-left: 40px;
        }
        @media(max-width:1200px){
            width: 100%;
            padding: 0;
        }
    }
    .about__text{
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 36px;
        color: $baseBlack;
        margin-bottom: 30px;
        @media(max-width:1024px){
            font-size: 16px;
            line-height: 30px;
            margin-bottom: 15px;
        }
    }

    .about__link{
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 16px;
        color: $basePink;
        transition: .3s;
        @media(max-width:1024px){
            font-size: 16px;
            line-height: 16px;
        }

        &:hover{
            color: $baseHover;
        }
    }
}


// homepage about-stat 

.homepage-about__stat {
    width: 100%;
    position: relative;

    .stat__title{
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 48px;
        color: $baseBlack;
        margin-bottom: 40px;
        @media(max-width:1024px){
            font-size: 22px;
            line-height: 30px;
            margin-bottom: 20px;
        }
    }

    .stat__block {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + 32px);
        margin-left: -16px;
        @media(max-width:1024px){
            width: calc(100% + 20px);
            margin-left: -10px;
        }
        @media(max-width:600px){
            width: 100%;
            margin: 0;
        }
    }
    .stat__item {
        margin-bottom: 50px;
        width: 20%;
        padding: 0 16px;
        @media(max-width:1600px){
            width: 25%;
        }
        @media(max-width:1200px){
            width: 33.333333333%;
        }
        @media(max-width:1024px){
            margin-bottom: 20px;
            padding: 0 10px;
        }
        @media(max-width:900px){
            width: 50%;
        }
        @media(max-width:600px){
            width: 100%;
            padding: 0;
        }
    }

    .stat__inner{
        width: 100%;
        height: 100%;
        display: flex;
    }

    .item__icon{
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 22px;
        .icon{
            width: 40px;
            height: 40px;
            transition: .3s;
            // stroke: $basePink;
            fill: $basePink;
        }
    }

    .item__text{
        width: calc(100% - 62px);
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
    }
}