.brands__content{
    width: 100%;
    position: relative;
    background: $baseWhite;
    padding: 30px 60px;
    padding-bottom: 60px;
    @media(max-width:1450px){
        padding: 30px 35px;
        padding-bottom: 60px;
    }
    @media(max-width:1024px){
        padding: 20px 10px;
        padding-bottom: 30px;
    }

    .content__container {
        width: 100%;
        max-width: 1520px;
        margin: 0 auto;
    }

    .content__title{
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 48px;
        color: $baseBlack;
        max-width: 100%;
        margin-bottom: 40px;
        position: relative;
        max-width: 1000px;
        @media(max-width:1024px){
            font-size: 26px;
            line-height: 32px;
            margin-bottom: 10px;
            padding: 0;
        }
        &:after{
            width:140px ;
            height: 4px;
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            transform: translateY(-30px);
            background: $basePink;
            border-radius: 3px;
            @media(max-width:1024px){
              transform: translateY(-18px);
          }
        }
    }
    .content__subtitle{
      font-family: Gilroy;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 36px;
      color: #252525;
      margin-bottom: 25px;
      @media(max-width:1024px){
        font-size: 18px ;
        line-height: 26px;
        margin-bottom: 20px;
      }
    }
    .content__accordeons{
        width: 100%;
        position: relative;

    }
    .product-main__pagination{
        margin-top: 50px;
        @media(max-width:1024px){
            margin-top: 37px;
        }
    }
}
.brands__nav{
  ul{
    padding: 18px 22px;
    display: flex;
    flex-wrap: wrap;
    border-bottom:1px solid #D0D8E4;
    @media(max-width:1024px){
      padding: 15px 0;
    }
    &:first-of-type{
      border-top:1px solid #D0D8E4;
    }
    a{
      font-family: Gilroy;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 36px;
      letter-spacing: 0.5em;
      color: #2C4378;
      &:hover, &.active{
        color: $basePink;
      }
    }
  }
}
.brands__wrap{
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  .brands-item{
    margin-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    @media(max-width:576px){
      margin-bottom: 20px;
      .brands-item__link{
        display: block;
      }
      &.is-hidden{
        .brands-item__link{
          display: none;
        }
      }
    }
    &.active{
      .brands-item__title{
        color: $basePink;
      }
    }
    &__title{
      font-family: Gilroy;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 42px;
      color: #2C4378;
      margin-right: 25px;
      @media(max-width:1024px){
        margin-right: 5px;
      }
    }
    ul{
      width: calc(100% - 65px);
      display: flex;
      flex-wrap: wrap;
      @media(max-width:576px){
        max-height: 145px;
        margin-bottom: 20px;
        overflow: hidden;
        &.is-open{
          max-height: 100%;
        }
      }
    }
    li{
      width: 20%;
      padding: 0 15px;

      @media(max-width:1200px){
        width: 33.3333%;
      }
      @media(max-width:768px){
        width: 50%;
      }
      @media(max-width:576px){
        width: 100%;
      }
    }
    a, li{
      font-family: Gilroy;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 36px;
      color: #434343;

      @media(max-width:1024px){
        font-size: 16px;
        line-height: 30px;
      }
    }
    a{
      &:hover{
        color: $basePink;
      }
    }
    &__link{
      display: none;
      width: 100%;
      font-family: Gilroy;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 16px;
      color: #DC2C43;
      cursor: pointer;
      .icon{
        width: 16px;
        height: 16px;
        fill: #DC2C43;
        transition: all ease 300ms;
      }
      .hidden{
        display: none;
      }
    }
  }
}
