.profile-hero {
    width: 100%;
    position: relative;
    background-color: $baseWhite;
    padding: 30px 60px;
    padding-bottom: 10px;
    @media(max-width:1450px){
        padding: 30px 35px;
        padding-bottom: 10px;
    }
    @media(max-width:1024px){
        padding: 20px 10px;
        padding-bottom: 0;
    }
    .hero__container{
        width: 100%;
        max-width: 1520px;
        margin: 0 auto;
    }

    .profile__title{
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 48px;
        color: $baseBlack;
        max-width: 100%;
        margin-bottom: 35px;
        position: relative;
        max-width: 1000px;
        @media(max-width:1024px){
            font-size: 26px;
            line-height: 32px;
            margin-bottom: 25px;
            padding: 0;
        }
        &:after{
            width:140px ;
            height: 4px;
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            transform: translateY(-30px);
            background: $basePink;
            border-radius: 3px;
            @media(max-width:1024px){
                transform: translateY(-18px);
            }
        }
    }

    .profile__menu {
        width: 100%;
        position: relative;
        border-bottom: 1px solid $baseBlack;
        @media(max-width:1024px){
            border:none;
            padding-bottom: 0;
        }
    }
    .profile-menu__trigger{
        display: none;
        @media(max-width:1024px){
            display: flex;
            cursor: pointer;
            position: relative;
            transition: .3s;
            padding-right: 26px;
            padding-bottom: 25px;
            border-bottom: 1px solid $basePink;
            .trigger__value{
                font-family: Gilroy;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 115%;
                color: $basePink;
            }

            .trigger__arrow{
                position: absolute;
                right: 0;
                top: 5px;
                transform: rotate(90deg);
                width:18px;
                height: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                .icon{
                    width:18px;
                    height: 12px;
                    fill: transparent;
                    stroke: $basePink;
                    stroke-width: 1.4px;   
                }
            }
        }
    }

    .profile-menu__links{
        width: 100%;
        display: flex;
        align-items: center;  
        @media(max-width:1024px){
            position: fixed;
            top: 0;
            right: -120%;
            width: 100%;
            height: 100vh;
            max-height: 100vh;
            overflow: hidden;
            overflow-y: auto;
            z-index: 20;
            background: $baseWhite;
            flex-direction: column;
            padding-bottom: 160px;
            transition: .3s;
            &.active{
                right: 0;
            }

        }
    }

    .profile__header {
        display: none;
        @media(max-width:1024px){
            display: flex;
            min-height: 56px;
            padding: 10px;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            background: $baseBack;
        }
        .profile__name{
            font-family: Gilroy;
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            line-height: 19px;
            margin-right: 15px;
            color: $baseBlack;
        }
        .profile__menu-close{
            width: 18px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: .3s;
            outline: none;
            background: transparent;
            border: none;
            cursor: pointer;

            .icon{
                width: 18px;
                height: 18px;
                fill: $basePink;
                transition: .3s;
                stroke-width: 0.5px;
                stroke: $basePink;
  
            }
        }
    }


    .profile-option__arrow {
        display: none;
        @media(max-width:1024px){
          display: flex;
          align-items: center;
          justify-content: center;
          width: 25px;
          height: 25px;
          position: relative;
          cursor: pointer;
          transform:rotate(90deg);
          transition: .3s;
          .icon{
            width: 18px;
            height: 10px;
            fill: transparent;
            stroke: $baseBlack;
            stroke-width: 1.2px;
            transition: .3s;
    
          }
          &.active{
            .icon{
              transform: rotate(90deg);
            }
          }
        }
      }

    .links-wrapper{
        width: 100%;
        display: flex;
        align-items: center;  
        @media(max-width:1024px){
            width: 100%;
            display: flex;
            flex-direction: column;
            position: relative;
        }
    }

    .profile__link{
        font-family: Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 115%;
        
        color: $baseBlack;
        transition: .3s;
        padding: 0 40px;
        position: relative;
        padding-bottom: 35px;
        @media(max-width:1550px){
            padding: 0 25px;
            padding-bottom: 30px;
            font-size: 16px;

        }
        @media(max-width:1200px){
            padding: 0 15px;
            padding-bottom: 25px;
            font-size: 15px;
        }
        @media(max-width:1024px){
            padding: 10px;
            min-height: 60px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            border-bottom: 1px solid #D0D8E4;
        }
        &:first-of-type{
            padding-left: 0;
            @media(max-width:1024px){
                padding-left: 10px;
            }
        }
        // &:last-of-type{
        //     padding-right: 0 ;
        // }
        &.active{
            color: $basePink;
            @media(max-width:1024px){
                color: $baseBlack;
            }
            

        }

        &.active:after{
            content: "";
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;
            transform: translateY(50%);
            height: 3px;
            background: $basePink;
            @media(max-width:1024px){
                display: none;
            }
        }
        &:hover{
            color: $basePink;
            .icon{
                stroke: $basePink;
            }
        }
    }
}