.product-tab{
  width: 100%;
  position: relative;
  background: $baseWhite;
  padding: 20px 60px;
  max-width: 1640px;
  margin: 0 auto;
  @media(max-width:1450px){
    padding: 20px 35px;
    max-width: 1590px;
  }
  @media(max-width:1024px){
    padding: 10px;
    padding-bottom: 20px;
  }
  &__nav{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #2C4378;
    overflow-x: auto;
    overflow-y: hidden;
    li{
      &:first-of-type{
        button{
          padding: 0 40px 32px 0;
          
          @media(max-width:1200px){
            padding: 0 30px 30px 0;
          }
          @media(max-width:1024px){
            padding: 15px 15px 15px 0;
          }
        }
      }
    }
    button{
      font-family: Gilroy;
      font-style: normal;
      transition: .3s;
      outline: none;
      font-weight: bold;
      z-index: 1;
      font-size: 20px;
      line-height: 24px;
      color: #252525;
      padding: 0 40px 32px 40px;
      cursor: pointer;
      border: 0;
      background: transparent;
      outline: none;
      position: relative;
      white-space: nowrap;
      @media(max-width:1200px){
        padding: 0 30px 30px 30px;
      }
      @media(max-width:1024px){
        padding: 15px;
      }
      &:after{
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: -1.5px;
        z-index: 1;
        width: 50%;
        height: 3px;
        opacity: 0;
        visibility: hidden;
        transition: all ease .2s;
      }
      &:hover, &.active{
        color: $basePink;
        &:after{
          opacity: 1;
          visibility: visible;
          background: $basePink;
          width: 100%;
        }
      }
    }
  }
  &__content{
    padding: 50px 0;
  }
  &__item{
    display: block;
    flex-wrap: wrap;
    visibility: hidden;
    position: absolute;
    left: -9999px;
    top: -9999px;
    margin-top: 50px;
    opacity: 0;
    -webkit-transition: margin-top .7s ease,opacity .4s ease;
    -o-transition: margin-top .7s ease,opacity .4s ease;
    transition: margin-top .7s ease,opacity .4s ease;
    &.active{
      visibility: visible;
      position: relative;
      left: 0;
      top: 0;
      margin-top: 0;
      opacity: 1;
    }

    ul{
      margin-bottom: 20px;
    }
    li{
      font-family: Gilroy;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 36px;
      color: #434343;
      padding-left: 20px;
      position: relative;
      &:before{
        position: absolute;
        content: '';
        left: 0;
        top: 10px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $basePink;
      }
    }
  }
  &__item-title{
    font-family: Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 46px;
    color: #252525;
    margin-bottom: 30px;
  }
  table{
    border-spacing: 0;
    width: 100%;
    tr{
      display: flex;
      width: 100%;
      @media(max-width:1024px){
        flex-direction: column;
      }
      &:nth-child(2n+1){
        background: #F2F6FC;
        border-radius: 10px;
      }
      &:nth-child(2n){
        background: #fff;
      }
    }
    td{
      padding: 20px 40px;
      font-weight: 300;
      color: #434343;
      font-family: Gilroy;
      font-style: normal;
      font-size: 18px;
      line-height: 32px;
      display: flex;
      @media(max-width:1024px){
        font-size: 16px;
        line-height: 22px;
        padding: 10px 15px;
      }
      &:nth-of-type(1){
        width: 350px;
        font-weight: bold;
        color: #252525;
        @media(max-width:1024px){
          width: 100%;
        }
      }
      &:nth-of-type(2){
        width: calc(100% - 350px);
        @media(max-width:1024px){
          width: 100%;
        }
      }
    }
  }

  .product-detail__menu {
    width: 100%;
    position: relative;
    border-bottom: 1px solid $baseBlack;
    @media(max-width:1024px){
        border:none;
        padding-bottom: 0;
    }
}
.product-detail-menu__trigger{
    display: none;
    @media(max-width:1024px){
        display: flex;
        cursor: pointer;
        position: relative;
        transition: .3s;
        padding-right: 26px;
        padding-bottom: 25px;
        border-bottom: 1px solid $basePink;
        .trigger__value{
            font-family: Gilroy;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 115%;
            color: $basePink;
        }

        .trigger__arrow{
            position: absolute;
            right: 0;
            top: 5px;
            transform: rotate(90deg);
            width:18px;
            height: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            .icon{
                width:18px;
                height: 12px;
                fill: transparent;
                stroke: $basePink;
                stroke-width: 1.4px;   
            }
        }
    }
}

.product-detail-menu__links{
    width: 100%;
    display: flex;
    align-items: center;  
    @media(max-width:1024px){
        position: fixed;
        top: 0;
        right: -120%;
        width: 100%;
        height: 100vh;
        max-height: 100vh;
        overflow: hidden;
        overflow-y: auto;
        z-index: 20;
        background: $baseWhite;
        flex-direction: column;
        padding-bottom: 160px;
        transition: .3s;
        &.active{
            right: 0;
        }

    }
}

.profile__header {
    display: none;
    @media(max-width:1024px){
        display: flex;
        min-height: 56px;
        padding: 10px;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        background: $baseBack;
    }
    .profile__name{
        font-family: Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 19px;
        margin-right: 15px;
        color: $baseBlack;
    }
    .product-detail__menu-close{
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: .3s;
        outline: none;
        background: transparent;
        border: none;
        cursor: pointer;

        .icon{
            width: 18px;
            height: 18px;
            fill: $basePink;
            transition: .3s;
            stroke-width: 0.5px;
            stroke: $basePink;

        }
    }
}


.profile-option__arrow {
    display: none;
    @media(max-width:1024px){
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      position: relative;
      cursor: pointer;
      transform:rotate(90deg);
      transition: .3s;
      .icon{
        width: 18px;
        height: 10px;
        fill: transparent;
        stroke: $baseBlack;
        stroke-width: 1.2px;
        transition: .3s;

      }
      &.active{
        .icon{
          transform: rotate(90deg);
        }
      }
    }
  }

.links-wrapper{
    width: 100%;
    display: flex;
    align-items: center;  
    @media(max-width:1024px){
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
    }
}

.profile__link{
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 115%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;

    
    color: $baseBlack;
    transition: .3s;
    padding: 0 40px;
    position: relative;
    padding-bottom: 35px;
    @media(max-width:1550px){
        padding: 0 25px;
        padding-bottom: 30px;
        // font-size: 16px;

    }
    @media(max-width:1200px){
        padding: 0 15px;
        padding-bottom: 25px;
        font-size: 16px;
    }
    @media(max-width:1024px){
        padding: 10px;
        min-height: 60px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        border-bottom: 1px solid #D0D8E4;
    }
    &:first-of-type{
        padding-left: 0;
        @media(max-width:1024px){
            padding-left: 10px;
        }
    }
    // &:last-of-type{
    //     padding-right: 0 ;
    // }
    &.active{
        color: $basePink;
        @media(max-width:1024px){
            color: $baseBlack;
        }
        

    }

    &.active:after{
        content: "";
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        transform: translateY(50%);
        height: 3px;
        background: $basePink;
        @media(max-width:1024px){
            display: none;
        }
    }
    &:hover{
        color: $basePink;
        .icon{
            stroke: $basePink;
        }
    }
}
}

