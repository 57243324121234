.category-hero{
  .products__menu {
    width: 100%;
    max-width: 1520px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 15px;
    border-top:1px solid $baseGrey ;
    border-bottom:1px solid $baseGrey ;
    @media(max-width:1024px){
      padding: 17px 10px;
      width: calc(100% + 20px);
      margin-left: -10px;
    }
  }
  .products__view{
    display: flex;
    align-items: center;
    margin-left: 15px;
    @media(max-width:1024px){
      display: none;
    }
  }

  .product-view__btn{
    width: 22px;
    height: 22px;
    outline: none;
    background: transparent;
    border: none;
    transition: .3s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    &:last-of-type{
      margin-right: 0;
    }
    .icon{
      width: 22px;
      height: 22px;
      transition: .3s;
      fill: $baseGrey;
    }

    &:hover .icon{
      fill: $basePink;
    }

    &.active{
      .icon{
        fill: $basePink;
      }
    }
  }


  .products__sort{
    display: flex;
    align-items: center;

  }

  .sort__holder{
    font-family: Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: $baseBlack;
    margin-right: 20px;
    @media(max-width:1024px){
      margin-right: 10px;
    }
  }
  .sort__block {
    position: relative;
}

.sort__trigger{
    display: none;
    @media(max-width:1024px){
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding-right: 15px;
        cursor: pointer;
        min-width: 95px;
        max-width: 95px;
    }
}

.sort__value{
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: $baseBlack;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden; 
    
}

.sort__icon{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    .icon{
        width: 10px;
        height: 5px;
        fill: transparent;
        stroke: $baseBlack;
        stroke-width: 1.2px;

    }
}
.sort-list__links{
    display: flex;
    align-items: center;
    @media(max-width:1024px){
        flex-direction: column;
        align-items: flex-start;

    }
}

.sort__list {
    display: flex;
    align-items: center;
    @media(max-width:1024px){
        // display: none;
        position: fixed;
        flex-direction: column;
        align-items: flex-start;
        top: 0;
        right: -120%;
        width: 100%;
        height: 100vh;
        max-height: 100vh;
        overflow: hidden;
        overflow-y: auto;
        background: $baseWhite;
        padding-bottom: 160px;
        z-index: 2;
        z-index: 20;
        transition: .3s;
        &.active{
            right: 0;
        }
        

    }
}

.sort__header {
    display: none;
    @media(max-width:1024px){
        margin-bottom: 10px;
        display: flex;
        min-height: 56px;
        padding: 10px;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        background: $baseBack;
    }
    .sort__title{
        font-family: Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 19px;
        margin-right: 15px;
        color: $baseBlack;
    }
    .sort__menu-close{
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: .3s;
        outline: none;
        background: transparent;
        border: none;
        cursor: pointer;

        .icon{
            width: 18px;
            height: 18px;
            fill: $basePink;
            transition: .3s;
            stroke-width: 0.5px;
            stroke: $basePink;

        }
    }
}

.sort__by{
    display: none;
    @media(max-width:1024px){
        display: block;
        font-family: Gilroy;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        color: $baseBlack;
        padding: 10px;
 }
}

.sort__item{
    cursor: pointer;
    transition: .3s;
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: $baseBlack;
    margin-right: 13px;
    &:last-of-type{
        margin-right: 0;
    }

    &:hover{
        color: $basePink;
    }
    &.active{
        color: $basePink;
        font-weight: 600;
    }
    @media(max-width:1024px){
        padding: 0 10px;
        margin-bottom: 10px;
        padding-left: 50px;
        min-height: 26px;
        display: inline-flex;
        align-items: center;
        position: relative;
        &:before{
            content: "";
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
            width: 26px;
            height: 26px;
            border-radius: 50%;
            border: 1px solid #E0E0E0;


        }
        &.active{
            color: $baseBlack;
            font-weight: normal;

        }
        &.active:before{
            border: 7px solid $basePink;
            
        }
        &:hover{
            color: $baseBlack;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
}
}
.category-products{
    .products__container{
        width: 100%;
        max-width: 1520px;
        margin: 0 auto;
        position: relative;
    }
    .products__list{
        width: calc(100% + 30px);
        margin-left: -15px;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        @media(max-width:1024px){
            width: calc(100% + 20px);
            margin-left: -10px;
            // padding-top: 30px;
        }
    }
}



.empty-results__title{
  font-family: Gilroy;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 36px;
  color: #252525;
  margin-bottom: 11px;
  span{
    margin-left: 6px;
    color: #DC2C43;
  }
}
.empty-results__subtitle{
  font-family: Gilroy;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 36px;
  color: #434343;
  margin-bottom: 20px;
}
.empty-results__link{
  margin-bottom: 18px;
}
.btn-primary{
  padding: 20px;
  width: 200px;
  display: inline-block;
  background: linear-gradient(90deg, #DC2C43 0%, #FF5369 100%);
  box-shadow: 0px 4px 4px rgba(233, 58, 81, 0.2);
  border-radius: 36px;
  font-family: Gilroy;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF;
  &:hover{
    transition: all .4s ease-in-out;
    background: linear-gradient(-90deg, #DC2C43 0%, #FF5369 100%);
    box-shadow: 0px 4px 4px rgba(233, 58, 81, 0.4);
  }
}
