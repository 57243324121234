.shipping-rules {
    width: 100%;
    padding: 50px 60px;
    position: relative;
    background: $baseWhite;
    padding-bottom: 130px;
    @media(max-width:1450px){
        padding-left: 35px;
        padding-right: 35px;
    }
    @media(max-width:1024px){
        padding: 40px 10px;
        padding-bottom: 55px;
    }

    .rules__container {
        width: 100%;
        max-width: 1520px;
        margin: 0 auto;
        position: relative;
    }

    .rules__text{
        h2{
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            line-height: 48px;
            color: $baseBlack;
            max-width: 100%;
            margin-bottom: 10px;
            position: relative;
            max-width: 1000px;
            @media(max-width:1024px){
                font-size: 26px;
                line-height: 32px;
                margin-bottom: 15px;
                padding: 0;
            }
            &:after{
                width:140px ;
                height: 4px;
                position: absolute;
                content: "";
                left: 0;
                top: 0;
                transform: translateY(-30px);
                background: $basePink;
                border-radius: 3px;
                @media(max-width:1024px){
                    transform: translateY(-18px);
                }
            }
        }
        p{
            font-family: Gilroy;
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 36px;
            color: $baseBlack;
            margin-bottom: 20px;
            @media(max-width:1024px){
                font-size: 16px;
                line-height: 30px;
            }
        } 

        ul {
            padding-left: 20px;
            margin-bottom: 20px;
            li{
                font-family: Gilroy;
                font-style: normal;
                font-weight: 300;
                font-size: 18px;
                line-height: 36px;
                color: $baseBlack;
                list-style-type: disc;
                @media(max-width:1024px){
                    font-size: 16px;
                    line-height: 30px;
                }

                &::marker{
                    font-size: 11px;
                }
            }
        }
    }


}