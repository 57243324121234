.catalog-wrap{
  display: flex;
  position: relative;
  background: $baseWhite;
  max-width: 1640px;
  margin: 0 auto;
  padding: 20px 60px;
  padding-bottom: 70px;
  @media(max-width:1450px){
    padding: 20px 35px;
    padding-bottom: 70px;
  }
  @media(max-width:1024px){
    padding: 30px 10px;
    padding-bottom: 45px;
    flex-wrap: wrap;
  }

  .product-main__pagination{
    @media(min-width: 1025px){
      display: none;
    }
    margin-top: 0;
  }
  .filter-wrap{
    width: 388px;
    min-width: 388px;
    padding-right: 48px;
    @media(max-width:1200px){
      width: 250px;
      padding-right: 15px;
      padding-left: 5px;
      min-width: 250px;
    }
    @media(max-width:1024px){
      display: none;
      width: 100%;
      padding-right: 0;
    }
  }
  .catalog-products{
    width: calc(100% - 388px);
    padding: 0;
    @media(max-width:1200px){
      width: calc(100% - 250px);
    }
    @media(max-width: 1024px){
      width: 100%;
    }
  }
  .product__item{
    width: 33.33333%;
    @media(max-width:1400px){
      width: 50%;
    }
    @media(max-width:1024px){
      width: 33.33333%;
    }
    @media(max-width:768px){
      width: 50%;
    }
    @media(max-width:576px){
      width: 100%;
    }
  }
}
.filter-item{
  padding-top: 15px;
  margin-bottom: 20px;
  border-top: 1px solid #D0D8E4;
  &__title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 15px;
    &.is-hidden{
      .icon{
        transform: rotate(-180deg);
      }
    }
    span{
      font-weight: 600;
      font-size: 15px;
      line-height: 24px;
      color: #252525;
    }
    .icon{
      width: 12px;
      height: 11px;
      fill: transparent;
      transition: .2s;
      stroke: $basePink;
      stroke-width: 1.2px;
    }
  }
  &__content{
    max-height: 180px;
    overflow: hidden;
    padding-top: 5px;
    transition: all ease .2s;
    &.is-active{
      max-height: 100%;
    }
  }
  .slider-range{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &__item{
      width: 100%;
      max-width: 155px;
      @media(max-width:1200px){
        max-width: 100px;
      }
      input{
        padding: 16px;
        width: 100%;
        border: 1px solid #D0D8E4;
        box-sizing: border-box;
        border-radius: 6px;
        font-family: Gilroy;
        font-weight: 300;
        font-size: 13px;
        line-height: 15px;
        color: #434343;
      }
    }
  }
  .sliderRange{
    margin: 15px auto 10px;
    width: calc(100% - 20px);
    background: #D0D8E4;
    border-radius: 4px;
    height: 4px;
    .ui-slider-range{
      background: #2C4378;
      border-radius: 4px;
      height: 4px;
    }
    .ui-state-default{
      outline: none;
      top: -6px;
      width: 17px;
      height: 17px;
      border-radius: 50%;
      border: 5px solid $basePink;
      box-sizing: border-box;
      background: $baseWhite;
    }
  }
  &__link{
    &.hidden{
      display: none !important;
    }
    button{
      background: transparent;
      border: 0;
      outline: none;
      cursor: pointer;
      font-family: Gilroy;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      color: #DC2C43;
      text-decoration: underline dashed 1px;
      &:hover{
        text-decoration: none;
      }
      .hidden{
        display: none;
      }
    }
  }
}
.filter-item__input{
  margin-bottom: 16px;
  label{
    font-family: Gilroy;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #434343;
    cursor: pointer;
    position: relative;
    padding-left: 39px;
    &:before, &:after{
      position: absolute;
      content: '';
    }
    &:before{
      left: 0;
      top: 50%;
      margin-top: -13px;
      width: 26px;
      height: 26px;
      background: #FFFFFF;
      border: 1px solid #D0D8E4;
      box-sizing: border-box;
      border-radius: 6px;
    }
    &:after{
      transition: all ease .2s;
      left: 5px;
      top: 50%;
      margin-top: -6px;
      opacity: 0;
      width: 16px;
      height: 12px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='12' viewBox='0 0 16 12' fill='none'%3E%3Cpath d='M2 5.57025C2.15319 5.9405 4.91489 8.67769 6.2766 10L14 2' stroke='%23DC2C43' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    }
  }
  input{
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -111;
    &:checked{
      & + label{
        &:after{
          opacity: 1;
        }
      }
    }
  }
}
