.shipping-delivery {
    width: 100%;
    position: relative;
    background: $baseWhite;
    padding: 30px 60px;
    padding-bottom: 60px;
    @media(max-width:1450px){
        padding-left: 35px;
        padding-right: 35px;
    }
    @media(max-width:1024px){
        padding: 20px 10px;
        padding-bottom: 40px;
    }
    .delivery__container {
        width: 100%;
        max-width: 1520px;
        margin: 0 auto;
        position: relative;
    }

    .delivery__title{
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 48px;
        color: $baseBlack;
        max-width: 100%;
        margin-bottom: 30px;
        position: relative;
        max-width: 1000px;
        @media(max-width:1024px){
            font-size: 26px;
            line-height: 32px;
            margin-bottom: 15px;
            padding: 0;
        }
        &:after{
            width:140px ;
            height: 4px;
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            transform: translateY(-30px);
            background: $basePink;
            border-radius: 3px;
            @media(max-width:1024px){
                transform: translateY(-18px);
            }
        }
    }

    .delivery__block {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        width: calc(100% + 30px);
        margin-left: -15px;
        @media(max-width:1024px){
            width: 100%;
            margin: 0;
        }

    }

    .delivery__item {
        width: 50%;
        padding: 0 15px;
        margin-bottom: 50px;
        @media(max-width:1024px){
            padding: 0;
            margin-bottom:30px ;
            width: 100%;
        }
    }

    .item__inner{
        width: 100%;
        height: 100%;

    }

    .item__icon {
        width: 52px;
        height: 52px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-bottom: 25px;
        .icon{
            width: 100%;
            height: 100%;
            fill: $basePink;
            max-width: 52px;
            max-height: 52px;
            object-fit: contain;
        }

    }

    .item__title{
        font-family: Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: $baseBlack;
        margin-bottom: 10px;
    }
    .item__text {
        p{
            font-family: Gilroy;
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 30px;
            color: $baseBlack;
        }
    }
    .delivery__map{
        width: 100%;
        position: relative;
        display: flex;
        justify-content: space-between;
        @media(max-width:1024px){
            flex-direction: column;

        }

    }

    .delivery-map__container{
        width: 100%;
        max-width: 1000px;
        margin-right: 53px;
        z-index: 1;
        filter: grayscale(15%);
        min-height: 460px;
        @media(max-width:1450px){
            margin-right: 40px;
        }
        @media(max-width:1024px){
            max-width: 100%;
        }

    }

    .delivery-map__info {
        width: 100%;
        max-width: 464px;
        padding-top: 25px;
        @media(max-width:1450px){
            max-width: 300px;
        }
        @media(max-width:1024px){
            padding-top: 30px;
        }
        p{
            font-family: Gilroy;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 30px;
            color: $baseBlack;
        }
    }

    
}