.homepage-hero {
    width: 100%;
    position: relative;
    background: $baseWhite;
    padding: 40px 60px;
    padding-top: 30px;
    @media(max-width:1450px){
        padding: 40px 35px;
    }
    @media(max-width:1024px){
        padding: 20px 10px;
        padding-bottom: 15px;
    }

    .hero-container {
        width: 100%;
        max-width: 1520px;
        margin: 0 auto;
        position: relative;
        display: flex;
        justify-content: space-between;
        @media(max-width:1024px){
            flex-direction: column;
        }
    }

    .hero__block{
        width: 100%;
        max-width: calc(100% - 390px);
        position: relative;
        border-bottom: 1px solid #D0D8E4;
        @media(max-width:1450px){
            max-width: calc(100% - 330px);
        }
        @media(max-width: 1024px){
            max-width: 100%;
            padding-bottom: 10px;
        }
    }

    .hero__info {
        width: calc(100% + 30px);
        margin-left: -15px;
        position: relative;
        display: flex;
        flex-wrap: wrap;

    }

    .info__item {
        width: 20%;
        padding: 0 17px;
        margin-bottom: 40px;
        @media(max-width:1350px){
           width: 33.33333%;
        }
        @media(max-width:1024px){
            margin-bottom: 20px;
        }
        @media(max-width:750px){
            width: 50%;
        }
        @media(max-width:500px){
            width: 100%;
        }
    }

    .item__inner{
        width: 100%;
        height: 100%;

    }

    .info-item__content{
        padding-left: 20px;
    }
    .info-item__content li{
        font-family: Gilroy;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 22px;
        color: $baseBlack;
        list-style-type:disc;
        b{
            font-weight: 300;
            color: $basePink;
        }

        &::marker{
            font-size: 11px;

        }

    }

    .info-item__top{
        display: flex;
        margin-bottom: 5px;
        @media(max-width:1024px){
            margin-bottom: 15px;
        }
    }

    .item__icon{
        width: 40px;
        height: 40px;
        min-height: 40px;
        min-width: 40px;
        margin-right: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon{
            width: 100%;
            height: 100%;
            max-width: 40px;
            max-height: 40px;
            object-fit: contain;
            fill: $basePink;
        }
        @media(max-width:1300px){
            margin-right: 10px;
        }
        @media(max-width:1024px){
            margin-right: 16px;
        }
    }

    .item__title{
        font-family: Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        color: $baseBlack;
        @media(max-width:1650px){
            font-size: 13px;
            line-height: 18px;
        }
        @media(max-width:1350px){
            font-size: 15px;
            line-height: 20px;
        }
    }

    .hero__slider{
        width: 100%;
        position: relative;
        overflow: hidden;
        margin-bottom: 45px;
        @media(max-width:1024px){
            margin-bottom: 40px;
        }
        .swiper-container{
            width: 100%;
            overflow: hidden;
            height: 500px;
            @media(max-width:1024px){
                height: 340px;
            }
        }

        .slide__item {
            width: 100%;
            height: 100%;
            border-radius: 16px;
            overflow: hidden;
            position: relative;
            z-index: 1;
            padding: 80px;
            display: flex;
            @media(max-width:1300px){
                padding: 40px;
            }
            @media(max-width:1024px){
                padding:35px 20px ;
            }

        }

        .slide__text {
            margin: auto 0;
            width: 100%;
            max-width: 660px;
            @media(max-width:1300px){
                max-width: 500px;
            }
            @media(max-width:1024px){
                max-width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }

        .slide__title{
            font-family: Gilroy;
            font-style: normal;
            font-weight: 800;
            font-size: 52px;
            line-height: 56px;
            color: $baseBlack;
            margin-bottom: 30px;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden; 
            @media(max-width:1300px){
                font-size: 44px;
                line-height: 48px;
            }
            @media(max-width:1024px){
                font-size: 28px;
                line-height: 32px;
                margin-bottom: 20px;
                text-align: center;
                -webkit-line-clamp: 4;
            }
        }
        .back__img{
            position: absolute;
            z-index: -1;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }


        .swiper-pagination{
            z-index: 2;
            bottom: 28px;
            left: 50%;
            transform: translateX(-50%);
            @media(max-width:1024px){
                bottom: 20px;
            }
        }

        .swiper-pagination-bullet{
           width: 8px;
           height: 8px;
           transform: scale(1);
           background-color: $baseWhite;
           margin-right: 8px;
           &:last-of-type{
               margin: 0;
           } 
        }

        .slide__btn{
            min-width: 200px;
            min-height: 58px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            transition: .3s;
            font-family: Gilroy;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: $baseWhite;
            padding: 10px 15px;
            background: linear-gradient(90deg, #DC2C43 0%, #FF5369 100%);
            box-shadow: 0px 4px 4px rgba(233, 58, 81, 0.2);
            border-radius: 36px;
            position: relative;
            overflow: hidden;
            z-index: 1;

            &:after{
                content: "";
                position: absolute;
                top: 0;
                z-index: -1;
                transition: .3s;
                opacity: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(90deg, #D21E35 0%, #EE3149 100%);
            }
            &:hover:after{
                opacity: 1;
            }
        }

        .slide__categorys{
            width: 100%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 30px;
            @media(max-width:1024px){
                display: none;
            }
            
        }
        .category__item {
            display: flex;
            align-items: center;
            margin-right: 25px;
            margin-bottom: 10px;
            &:last-of-type{
                margin-right: 0;
            }
        }

        .item__icon{
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            .icon{
                width: 32px;
                height: 32px;
                fill: transparent;
                stroke: $basePink;
                stroke-width: 1.8px;
            }
        }
        .item__text{
            font-family: Gilroy;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 25px;
            color: $baseBlack;
        }
    }

    .hero__menu{
        width: 100%;
        max-width: 360px;
        margin-right: 30px;
        @media(max-width:1450px){
            max-width: 300px;
        }
        @media(max-width:1024px){
            position: fixed;
            z-index: 20;
            top: 0;
            right: -120%;
            transition: .3s;
            max-width: 100%;
            margin: 0;
            height: 100vh;
            overflow-y: auto;
            background: $baseBack;
            &.active{
                right: 0;
            }
        }
    }

    .hero-menu__links{
        width: 100%;
        position: relative;

    }
    .hero-menu__top {
        display: none;
        @media(max-width:1024px){
            display: flex;
            min-height: 56px;
            padding: 10px;
            align-items: center;
            justify-content: space-between;
            background: $baseBlue;
        }
        .hero-menu__name{
            font-family: Gilroy;
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            line-height: 19px;
            margin-right: 15px;
            color: $baseWhite;
        }
        .hero-menu__close{
            width: 18px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: .3s;
            .icon{
                width: 18px;
                height: 18px;
                fill: $baseWhite;
                transition: .3s;
                stroke-width: 0.5px;
                stroke: $baseWhite;

            }
        }
    }

    .hero-category__link{
        cursor: pointer;
        background: $baseBack;
        display: flex;
        align-items: center;
        padding: 8px 13px;
        width: 100%;
        border-bottom: 1px solid #D0D8E4;
        transition: .3s;
        min-height: 47px;
        position: relative;
        padding-right: 45px;

        &:first-of-type{
            border-radius: 8px 8px 0px 0px;
            @media(max-width:1024px){
                border-radius: 0;
            }
        }

        &:last-of-type{
            border-radius: 0px 0px 8px 8px;
            border: none;
            @media(max-width:1024px){
                border-bottom: 1px solid #D0D8E4;
                border-radius: 0;
            }
        }
        @media(max-width:1024px){
            padding-right:40px ;
        }


        .link__icon{
            width: 30px;
            height: 30px;
            margin-right: 18px;
            display: flex;
            align-items: center;
            min-width: 30px;
            min-height: 30px;
            justify-content: center;
            .icon{
                width: 100%;
                height: 100%;
                max-width: 30px;
                max-height: 30px;
                object-fit: contain;
                fill: $baseBlue;
                transition: .3s;
            }
        }
    
        .link__arrow {
            width: 20px;
            height: 10px;
            position: absolute;
            right: 13px;
            top: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateY(-50%) rotate(90deg);
            .icon{
                width: 20px;
                height: 10px;
                fill: transparent;
                stroke: $baseBlue;
                stroke-width: 1.2px;
                transition: .3s;
            }
            @media(max-width:1024px){
                right: 10px;
            }
        }

        .link__name{
            font-family: Gilroy;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: $baseBlack;
            transition: .3s;
        }

    }

    
}