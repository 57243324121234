.blog-interesting{
    width: 100%;
    position: relative;
    background: $baseWhite;
    padding: 95px 60px;
    padding-bottom: 140px;
    @media(max-width:1450px){
        padding: 95px 35px ;
        padding-bottom: 140px;
    }
    @media(max-width:1024px){
        padding: 40px 10px;
        padding-bottom: 75px;
    }
    .interesting__container {
        width: 100%;
        max-width: 1520px;
        margin: 0 auto;
    }

    .interesting__title{
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 48px;
        color: $baseBlack;
        max-width: 100%;
        margin-bottom: 30px;
        position: relative;
        max-width: 1000px;
        padding-right: 120px;
        @media(max-width:1024px){
            font-size: 26px;
            line-height: 32px;
            margin-bottom: 25px;
            padding: 0;
        }
        &:after{
            width:140px ;
            height: 4px;
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            transform: translateY(-30px);
            background: $basePink;
            border-radius: 3px;
            @media(max-width:1024px){
                transform: translateY(-18px);
            }
        }
    }


    .article__item  {
        width: 33.333333333%;
        padding: 0 22px;
    }
    .article__inner{
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        

    }

    .swiper-slide{
        display: flex;
        height: auto;
    }

    .article__photo{
        display: flex;
        margin-bottom:25px;
        width: 100%;
        height: 260px;
        position: relative;
        @media(max-width:1024px){
            height: 200px;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .article__name{
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: $baseBlack;
        margin-bottom: 21px;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        @media(max-width:1024px){
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 15px;
        }
        @media(max-width:580px){
            -webkit-line-clamp: 3;
        }
    }

    .article__info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .article__date{
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 14px;
        color: $baseBlack;
        opacity: .5;
        margin-right: 10px;
    }

    .article__time{
        display: flex;
        align-items: center;
        margin-left: 10px;
    }

    .time__icon {
        width: 19px;
        height: 19px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon{
           stroke: #919191; 
           fill: transparent;
           width: 19px;
           height: 19px;
        }
    }

    .time__value{
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 14px;
        color: $baseBlack;
        opacity: .5;
        
    }
    .article__slider{
        position: relative;
    }

    .swiper-next{
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        display: flex;
        outline: none;
        align-items: center;
        justify-content: center;
        transition: .3s;
        cursor: pointer;
        transform: translateY(-67px);
        .icon-arrow-slider{
            width: 46px;
            height: 18px;
            fill: $basePink;
            @media(max-width:1024px){
                display: none;

            }
        }

        .icon-arrow{
            display: none;
            @media(max-width:1024px){
                display: block;
                width: 16px;
                height: 8px;
                fill: transparent;
                transition: .3s;
                stroke: $baseWhite;
                stroke-width: 1.5px;
                transform: rotate(90deg);
            }
        }

        @media(max-width:1250px){
            display: flex;
        }
        @media(max-width:1024px){
            background: rgba(220,44,67,0.5);
            width: 20px;
            height: 46px;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateX(0) translateY(-50%);
            top: 50%;
        }
    }


    .swiper-prev{
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s;
        outline: none;
        cursor: pointer;
        transform: translateY(-67px) translateX(-68px) scale(-1,1);
        .icon-arrow-slider{
            width: 46px;
            height: 18px;
            fill: $basePink;
            @media(max-width:1024px){
                display: none;

            }
        }

        .icon-arrow{
            display: none;
            @media(max-width:1024px){
                display: block;
                width: 16px;
                height: 8px;
                fill: transparent;
                transition: .3s;
                stroke: $baseWhite;
                stroke-width: 1.5px;
                transform: rotate(-90deg);
            }
        }
        @media(max-width:1250px){
            display: flex;
        }
        @media(max-width:1024px){
            background: rgba(220,44,67,0.5);
            width: 20px;
            height: 46px;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateX(0) translateY(-50%);
            top: 50%;
            right: auto;
            left: 0;
        }
    }

    .article__link{
        display: none;
        @media(max-width:1024px){
            display: inline-flex;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 16px;
            color: $basePink;
            transition: .3s;
            margin-top: 23px;
        }
    }
    .swiper-container{

        .article__item {
            width: 100%;
            margin: 0;
            padding: 0;
        }
    }
}