.search-hero {
  width: 100%;
  position: relative;
  background: $baseWhite;
  padding: 0 0 20px;
  .search__title{
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
    color: $baseBlack;
    max-width: 100%;
    margin-bottom: 40px;
    position: relative;
    @media(max-width:1024px){
      font-size: 26px;
      line-height: 32px;
      margin-bottom: 25px;
    }

    span {
      margin: 0 7px;
      &.featured {
        color: $basePink;
        @media(max-width: 576px){
          display: block;
          margin-left: 0;
        }
      }
    }

    &:after{
      width:140px ;
      height: 4px;
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      transform: translateY(-30px);
      background: $basePink;
      border-radius: 3px;
    }
  }
   // breadcrumbs
   .breadcrumbs{
     padding-left: 0;
     margin-bottom: 60px;
   }
}

