.news-detail__info, .blog-detail__info{
    width: 100%;
    position: relative;
    background: $baseWhite;
    padding: 30px 60px;
    padding-bottom:150px ;
    @media(max-width:1450px){
        padding: 20px 35px;
        padding-bottom: 150px;
    }
    @media(max-width:1024px){
        padding: 20px 10px;
        padding-bottom:75px ;
    }

    .info__container {
        width: 100%;
        max-width: 1520px;
        margin: 0 auto;

    }

    .news-detail__title{
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 48px;
        color: $baseBlack;
        max-width: 100%;
        margin-bottom: 30px;
        position: relative;
        max-width: 1000px;
        @media(max-width:1024px){
            font-size: 26px;
            line-height: 32px;
            margin-bottom: 25px;
            padding: 0;
        }
        &:after{
            width:140px ;
            height: 4px;
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            transform: translateY(-30px);
            background: $basePink;
            border-radius: 3px;
            @media(max-width:1024px){
                transform: translateY(-18px);
            }
        }
    }

    .news-detail__row {
        width: 100%;
        display: flex;
        align-items: flex-start;
        @media(max-width:1024px){
            flex-direction: column;

        }
    }

    .news-detail__block{
        width: 100%;
        position: relative;
        max-width: 1000px;
        margin-right: 30px;
        @media(max-width:1450px){
            margin-right: 20px;
        }
    }

    .news-detail__img{
        width: 100%;
        max-width: 1000px;
        height: 460px;
        object-fit: cover;
        border-radius: 8px;
        @media(max-width:1024px){
            max-width: 100%;
            // height: 300px;
        }
        @media(max-width:770px){
            height: 300px;
        }
        @media(max-width:450px){
            height: 160px;
        }
    }

    .news__author{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;
        border-bottom: 1px solid $baseGrey;
        @media(max-width:1024px){
            flex-direction: column;
            align-items: flex-start;

        }
    }

    .author__detail{
        display: flex;
        align-items: center;
        margin-right: 15px;
        @media(max-width:1024px){
            margin-bottom: 17px;
        }
    }
    .author__photo {
        width: 72px;
        height: 72px;
        min-height: 72px;
        min-width: 72px;
        margin-right: 20px;
        border-radius: 50%;
        overflow: hidden;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;

        }
    }

    .author__placeholder{
        font-family: Gilroy;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 22px;
        color: $baseBlack;
    }

    .author__name{  
        font-family: Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: $baseBlack; 
    }

    .author__info{
        display: flex;
        align-items: center;

    }

    .author__time{
        font-family: Gilroy;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 14px;
        color: $baseBlack;
        opacity: .5;
        margin-right: 48px;
        @media(max-width:1200px){
            margin-right: 20px;
        }
        @media(max-width:1024px){
            margin-right: 48px;
        }
    }

    .author__duration {
        display: flex;
        align-items: center;
        .icon{
            width: 18px;
            height: 18px;
            fill: transparent;
            stroke-width: 1.2px;
            stroke: #919191;
            margin-right: 10px;
        }
        font-family: Gilroy;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 14px;
        color: #222222;
        opacity: 0.5;
    }

    .news-detail__text {
        padding-top: 35px;
        @media(max-width:1024px){
            padding-top: 30px;
        }
        p{
            font-family: Gilroy;
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 36px;
            color: #434343;
            margin-bottom: 26px;
            @media(max-width:1024px){
                font-size: 16px;
                line-height: 30px;
                margin-bottom: 20px;
            }
        }

        h3{
            font-family: Gilroy;
            font-style: normal;
            font-weight: bold;
            font-size: 26px;
            line-height: 36px;
            color: $baseBlack;
            margin-bottom: 5px;
            @media(max-width:1024px){
                font-size: 20px;
                line-height: 30px;
            }
        }

        ul{
            padding-left: 20px;
            margin-bottom: 26px;
            @media(max-width:1024px){
                margin-bottom: 20px;
            }
            
            li{
                font-family: Gilroy;
                font-style: normal;
                list-style-type: disc;
                font-weight: 300;
                font-size: 18px;
                line-height: 36px;
                color: #434343;
                @media(max-width:1024px){
                    font-size: 16px;
                    line-height: 30px;
                }
                &::marker{
                    font-size: 14px;
                }
            }
        }
        img{
            margin-top: 14px;
            margin-bottom: 40px;
            @media(max-width:1024px){
                margin-top: 10px;
                margin-bottom: 30px;
            }
        }
    }

    .news-popular{
        width: calc(100% - 1030px);
        min-width: 470px;
        position: relative;
        padding: 34px 40px;
        border: 1px solid $baseGrey;
        box-sizing: border-box;
        border-radius: 8px;
        @media(max-width:1450px){
            padding: 35px 28px;
            min-width: 450px;
        }
        @media(max-width:1200px){
            padding: 32px 15px;
            min-width: 400px
        }
        @media(max-width:1024px){
            margin-top: 40px;
            padding: 25px 20px;
            padding-bottom: 40px;
            min-width: auto;
            width: 100%;
            max-width: 100%;
        }
    }

    .popular__title{
        font-family: Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 36px;
        color: $baseBlack;
        margin-bottom: 22px;
        @media(max-width:1024px){
            font-size: 23px;
            line-height: 36px;
            margin-bottom: 22px;
        }
    }

    .popular__items {
        width: 100%;
    }

    .popular__item {
        width: 100%;
        display: flex;
        margin-bottom: 20px;
        &:last-of-type{
            margin-bottom: 0;
        }
        @media(max-width:1024px){
            flex-direction: column;
            margin-bottom: 30px;

        }

    }

    .item__photo {
        width: 130px;
        height: 116px;
        min-width: 130px;
        min-height: 116px;
        border-radius: 8px;
        overflow:hidden;
        background: $baseBack;
        position: relative;
        margin-right: 25px;
        @media(max-width:1200px){
            margin-right: 17px;
        }
        @media(max-width:1024px){
            margin-right: 0;
            margin-bottom:18px ;
        }
        img{
            width: 100%;
            height: 100%;
            max-width: 115px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            object-fit: cover;
        }
    }

    .item__name{
        font-family: Gilroy;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        color: $baseBlack;
        transition: .3s;
        display: inline-flex;
        margin-bottom: 5px; 
    }

    .item__price {
        font-family: Gilroy;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 17px;
        letter-spacing: 0.06em;
        color: #434343;
    }


    
}