.modal {
  button {
    border: 0;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  &__btn {
    min-height: 60px;
    font-size: 15px;
  }
  .btn_close {
    &.absolute {
      width: 37px;
      height: 37px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      right: -50px;
      top: -37px;
      border: 0;
      z-index: 100;
      background: transparent;
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      .icon {
        width: 37px;
        height: 37px;
        fill: $baseWhite;
        transition: all .3s ease;
      }
      &:hover {
        .icon {
          fill: $basePink;
        }
      }
    }
  }
  .form {
    &-item {
      margin-bottom: 16px;
      &.error {
        .form-item__input {
          border-color: $basePink;
        }
      }
      &__title {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 11px;
      }
      &__input {
        font-family: Gilroy;
        font-size: 16px;
        width: 100%;
        padding: 14px 20px;
        height: 60px;
        border: 1px solid $baseLightGrey;
        background: $secondaryBlue;
        border-radius: 6px;
        transition: all .3s ease;
      }
    }
    &-info {
      margin-bottom: 30px;
      font-weight: 300;
      font-size: 14px;
      span {
        margin-right: 5px;
      }
      .featured {
        color: $basePink;
      }
    }
  }
}

@media(max-width: 992px){
  .modal {
    .btn_close {
      &.absolute {
        display: flex;
        left: 50%;
        top: -60px;
        transform: translateX(-30%);
      }
    }
  }
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  padding: 15px;
  padding-top: 30px;
  overflow: hidden ;
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  
  @media(max-width:1024px){
    padding: 80px 15px;
    padding-bottom: 100px;
    display: block;
  }
}

.modal__wrap {
  margin-top: 50px;
  // max-height: calc(100vh - 190px);
  position: relative;
  background: $baseWhite;
  width: 100%;
  max-width: 480px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 8px;
  
  box-shadow: 0px 0px 70px rgba(138, 138, 138, 0.1);
  @media(max-width:1024px){
    margin-top: 0;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  @media(max-width: 568px){
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.modal__container {
  padding: 42px 38px 44px 42px;
  // max-height: 100vh;
  // overflow-x: hidden;
  // overflow-y: auto;
  @media(max-width: 992px){
    padding: 32px 32px 44px;
  }
}

.modal__main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.modal__info {
  font-weight: 300;
  font-size: 24px;
  line-height: 30px;
  max-width: 490px;
  margin: 0 auto;
  text-align: center;
}

.modal__title {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  @media(max-width: 568px){
    font-size: 20px;
    line-height: 26px;
  }
}

.modal__text {
  max-width: 383px;
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
}

.modal__bg-transparent {
  .modal__overlay {
    background-color: rgba(0,0,0,0);
  }
}

.micromodal-slide {
  display: none;
  &.is-open {
    display: block;
  }
}

@-webkit-keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}

@-webkit-keyframes mmslideOut {
  from {
    transform:  translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}

@keyframes mmslideOut {
  from {
    transform:  translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}

.micromodal-slide[aria-hidden="false"] {
  .modal__overlay {
    -webkit-animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
    animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
  }
  .modal__container {
    -webkit-animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
    animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
  }
}
.micromodal-slide[aria-hidden="true"] {
  .modal__overlay {
    -webkit-animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
    animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
  }
  .modal__container {
    -webkit-animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
    animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
  }
}
.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}

@import "./success";
@import "./password-message";
@import "./restore-password";
@import "./login";
@import "./register";
