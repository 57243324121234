.faq__content {
    width: 100%;
    position: relative;
    background: $baseWhite;
    padding: 30px 60px;
    padding-bottom: 60px;
    @media(max-width:1450px){
        padding: 20px 35px;
        padding-bottom: 60px;
    }
    @media(max-width:1024px){
        padding: 20px 10px;
        padding-bottom: 30px;
    }

    .content__container {
        width: 100%;
        max-width: 1520px;
        margin: 0 auto;
    }

    .content__title{
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 48px;
        color: $baseBlack;
        max-width: 100%;
        margin-bottom: 40px;
        position: relative;
        max-width: 1000px;
        @media(max-width:1024px){
            font-size: 26px;
            line-height: 32px;
            margin-bottom: 25px;
            padding: 0;
        }
        &:after{
            width:140px ;
            height: 4px;
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            transform: translateY(-30px);
            background: $basePink;
            border-radius: 3px;
            @media(max-width:1024px){
                transform: translateY(-18px);
            }
        }
    }

    .content__accordeons{
        width: 100%;
        position: relative;

    }
    .product-main__pagination{
        margin-top: 50px;
        @media(max-width:1024px){
            margin-top: 37px;
        }
    }
}


// accordeon

.accordeon__item{
    width: 100%;
    position: relative;
    margin-bottom: 10px;
    &:last-child{
        margin-bottom: 0;
    }
    &.active{
        .accordeon__arrow .icon{
            transform: rotate(180deg);
        }
    }
    .accordeon__trigger{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 32px 38px;
        min-height: 84px;
        background: $baseWhite;
        border-radius: 6px;
        border: 1px solid $baseGrey;
        position: relative;
        cursor: pointer;
        padding-right: 75px;
        @media(max-width:1024px){
            padding: 20px 25px;
            padding-right: 50px;
        }
    }

    .accordeon__name{
        font-family: Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        color: $baseBlack;
        @media(max-width:1024px){
            line-height: 24px;
            font-size: 16px;

        }
    }

    .accordeon__arrow{
        position: absolute;
        top: 50%;
        right: 35px;
        transform: translateY(-50%) scale(1,-1);
        width: 30px;
        height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media(max-width:1024px){
            right: 15px;
        }
        .icon{
            width: 30px;
            height: 14px;
            fill: transparent;
            stroke: $basePink;
            stroke-width: 1.5px;
            transition: .3s;
            height: 12px;
        }
    }

    .accordeon__content{
        width: 100%;
        padding: 32px 38px;
        padding-bottom: 22px;
        border-radius: 6px;
        display: none;
        @media(max-width:1024px){
            padding: 20px 25px;
            padding-bottom: 10px;
        }
        p{
            font-family: Gilroy;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 22px;
            color: $baseBlack;
            @media(max-width:1024px){
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
}