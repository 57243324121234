.profile-content{
    width: 100%;
    padding: 40px 60px;
    background: $baseWhite;
    position: relative;
    padding-bottom: 200px;
    @media(max-width:1450px){
        padding: 40px 35px;
        padding-bottom: 150px;

    }
    @media(max-width:1024px){
        padding: 30px 10px;
        padding-bottom: 75px;
    }
    .profile__container {
        width: 100%;
        position: relative;
        max-width: 1520px;
        margin: 0 auto;

    }

    .profile__block {
        width: 100%;
        position: relative;
        &.active{
            .profile-main__info {
                display: none !important;
            }
            .profile-edit__info{
                display: block !important;
            }
        }
        
    }
    .profile__side{
        margin-right: 57px;
        position: relative;
        width: 100%;
        max-width: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media(max-width:1024px){
            max-width: 140px;
            margin-right: 20px;

        }
        @media(max-width:700px){
            margin-bottom: 25px;
            margin-right: 0;
        }
    }
    .profile__photo {
        width: 200px;
        height: 200px;
        min-width: 200px;
        min-height: 200px;
        position: relative;
        border-radius: 8px;
        overflow: hidden;
        @media(max-width:1024px){
            min-width: 140px;
            width: 140px;
            height: 140px;
            min-height: 140px;
        }
        img{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .profile-info {
        width: calc(100% - 257px);
        position: relative;
    }

    // profile-main__info 
    .profile-main__info {
        width: 100%;
        position: relative;
        display: flex;
        @media(max-width:700px){
            flex-direction: column;

        }


    }
    .info__block{
        width: 100%;
        max-width: 540px;
        @media(max-width:700px){
            max-width: 100%;
        }
    }

    .info__item {
        display: flex;
        margin-bottom: 10px;
        @media(max-width:700px){
            flex-direction: column;
        }

    }

    .item__placeholder{
        min-width:185px;
        margin-right: 28px;
    }
    .item__value{
        max-width: 326px;
        width: 100%;
    }

    .item__placeholder{
        font-family: Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 32px;
        color: $baseBlack;
        @media(max-width:1024px){
            line-height: 24px;
        }
    }

    .item__value{
        font-family: Gilroy;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 32px;
        color: $baseBlack;
        @media(max-width:1024px){
            line-height: 24px;
        }
    }

    .info__buttons {
        display: flex;
        align-items: center;
        margin-top: 25px;
        @media(max-width: 1024px){
            margin-top: 20px;
            // justify-content: space-between;
        }

    }

    .edit__btn{
        display: inline-flex;
        min-height: 48px;
        background: linear-gradient(90deg, #DC2C43 0%, #FF5369 100%);
        box-shadow: 0px 4px 4px rgba(233, 58, 81, 0.2);
        border-radius: 36px;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        /* or 129% */
        min-width: 160px;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 10px 15px;
        color: $baseWhite;
        transition: .3s;
        position: relative;
        overflow: hidden;
        z-index: 1;
        outline: none;
        border: none;
        cursor: pointer;
        margin-right: 30px;
        @media(max-width:350px){
            margin-right: 20px;
        }

        &:after{
            content: "";
            position: absolute;
            top: 0;
            z-index: -1;
            transition: .3s;
            opacity: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, #D21E35 0%, #EE3149 100%);
        }
        &:hover:after{
            opacity: 1;
        }
    }

    .change__password{
        background: transparent;
        outline: none;
        cursor: pointer;
        border: none;
        font-family: Gilroy;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: $basePink;
        border-bottom: 1px dashed #DC2C43;
    }

    // .profile-edit__info

    .profile-edit__info{
        width: 100%;
        position: relative;
        display: none;
        
    }

    .form-profile{
        display: flex;
        @media(max-width:700px){
            flex-direction: column;
        }
    }

    .form__block{
        width: 100%;
        position: relative;
        display: flex;
        max-width: 830px;
        flex-direction: column;
        @media(max-width:1024px){
            max-width: 100%;
        }
    }

    .form__row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        margin-bottom: 20px;
        @media(max-width:1024px){
            margin-bottom: 15px;
        }
        @media(max-width:700px){
            flex-direction: column;

        }
    }

    .form-item{
        position: relative;
        line-height: 100%;
        width: calc(50% - 15px);
        @media(max-width:1024px){
            width: calc(50% - 7.5px);
        }
        @media(max-width:700px){
            width: 100%;
            margin-bottom: 15px;
            &:last-child{
                margin: 0;
            }
        }

        &.error,  &.error__email{
            input{
                border-color: $basePink;
            }
        }
        
    }

    .item__title{
        font-family: Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 36px;
        color: $baseBlack;
        margin-bottom: 4px;
    }

    .form__input{
        min-height: 60px;
        background: #FCFDFF;
        /* 5 */
        border: 1px solid #C0C9D7;
        box-sizing: border-box;
        border-radius: 6px;
        outline: none;
        width: 100%;
        padding: 10px 20px;
        font-family: Gilroy;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 36px;
        color: $baseBlack;
        transition: .3s;
        @media(max-width:570px){
            font-size: 16px;
        }
    }


    .form__btn{
        margin-top: 35px;
        display: inline-flex;
        min-height: 60px;
        background: linear-gradient(90deg, #DC2C43 0%, #FF5369 100%);
        box-shadow: 0px 4px 4px rgba(233, 58, 81, 0.2);
        border-radius: 36px;
        font-style: normal;
        font-weight: bold;
        font-family: Gilroy;
        font-size: 15px;
        line-height: 18px;
        /* or 129% */
        min-width: 200px;
        max-width: 200px;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 10px 15px;
        color: $baseWhite;
        transition: .3s;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        z-index: 1;
        border: none;
        outline: none;
        @media(max-width:1024px){
            margin-top: 35px;
        }

        &:after{
            content: "";
            position: absolute;
            top: 0;
            z-index: -1;
            transition: .3s;
            opacity: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, #D21E35 0%, #EE3149 100%);
        }
        &:hover:after{
            opacity: 1;
        }
    }

    .form-checkbox{
        display: flex;
        align-items: center;
        margin-top: 35px;
        @media(max-width:700px){
            margin-top: 20px;
            margin-bottom: 0;
        }

    }

    .check__retangle {
        width: 32px;
        height: 32px;
        min-height: 32px;
        cursor: pointer;
        min-width: 32px;
        border: 1px solid #C0C9D7;
        box-sizing: border-box;
        border-radius: 6px;
        background: transparent;
        margin-right: 18px;
        outline: none;
    }

    .check__text{
        font-family: Gilroy;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
    }

    .input__checkbox:checked + .check__retangle{
        background: $basePink;
        border-color: transparent;
    }
    .input__checkbox{
        display: none;
    }

    [type="file"] {
        height: 0;
        overflow: hidden;
        width: 0;
      }
      
    [type="file"] + label {
        margin-top: 20px;
        display: inline-flex;
        min-height: 48px;
        background: linear-gradient(90deg, #DC2C43 0%, #FF5369 100%);
        box-shadow: 0px 4px 4px rgba(233, 58, 81, 0.2);
        border-radius: 36px;
        font-style: normal;
        font-weight: bold;
        font-family: Gilroy;
        font-size: 14px;
        line-height: 18px;
        /* or 129% */
        min-width: 160px;
        max-width: 200px;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 10px 15px;
        color: $baseWhite;
        transition: .3s;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        z-index: 1;
        border: none;
        outline: none;
        @media(max-width:1024px){
            max-width: 140px;
            min-width: auto;
            width: 100%;
            font-size: 13px;
        }

        &:after{
            content: "";
            position: absolute;
            top: 0;
            z-index: -1;
            transition: .3s;
            opacity: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, #D21E35 0%, #EE3149 100%);
        }
        &:hover:after{
            opacity: 1;
        }
    }





}