.comparison {
  .container {
    max-width: 1640px;
    padding-left: 60px;
    padding-right: 60px;
    margin: 0 auto;
    @media(max-width: 1450px) {
      padding-left: 35px;
      padding-right: 35px;
      max-width: 1590px;
    }
    @media(max-width: 1024px) {
      padding-right: 10px;
      padding-left: 10px;
    }
  }

  .homepage__diskount{
    padding-bottom: 180px;
    @media(max-width:1024px){
      padding-bottom: 75px;
    }
  }
  &-empty {
    display: none;
    margin-bottom: 55px;
    &.active {
      display: block;
    }
  }
  &__items {
    display: flex;
    //flex-wrap: wrap;
    width: 1520px;
    margin-bottom: 55px;
    position: relative;
    @media(max-width: 768px){
      flex-wrap: wrap;
      width: auto;
      overflow: hidden;
      margin-bottom: 20px;
    }

    &.disabled:before {
      content: '';
      background: $baseWhite;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: all .3s ease;
      z-index: 5;
    }
    &.active:before {
      opacity: 0;
      z-index: -1;
    }
    &.hidden {
      display: none;
    }
    &-wrap {
      overflow-x: auto;
    }
  }
  &__item {
    width: 229px;
    @media(max-width: 768px){
      width: 100%;
    }
    &:not(&:last-child) {
      margin-right: 30px;
      @media(max-width: 768px){
        margin-right: 0;
      }
    }
    &-titles {
      .product__info {
        &:nth-child(2n) {
          position: relative;
          &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 1520px;
            background: $lightGray;
            border-radius: 6px;
            z-index: -1;
          }
        }
      }
    }
    &-title {
      font-weight: bold;
      &_back {
        color: $basePink;
        font-weight: bold;
        transition: all .3s ease;
        &:hover {
          .icon-arrow-text {
            transform: rotate(
                180deg
            ) translateX(-3px);
          }
        }
        .icon-arrow-text {
          fill: #DC2C43;
          width: 16px;
          height: 16px;
          transform: rotate(
              180deg
          );
          margin-right: 14px;
          margin-bottom: 2px;
          transition: all .3s ease;
        }
      }
    }
  }
  &__item .product__info {
    padding: 15px 5px 15px 0;
  }
  &__item:first-child  .comparison__item-title_back {
    padding: 0;
  }
  &__item-title {
    padding-left: 28px;
  }
  .product {
    &-main__img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &__info {
      &-size {
        display: block;
        margin-bottom: 30px;
      }
      &-text {
        margin-bottom: 21px;
      }
    }
    &__btn {
      margin-top: auto;
      max-width: 160px;
      display: inline-flex;
      min-height: 48px;
      background: linear-gradient(90deg, #DC2C43 0%, #FF5369 100%);
      box-shadow: 0px 4px 4px rgba(233, 58, 81, 0.2);
      border-radius: 36px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      /* or 129% */
      min-width: 160px;
      text-align: center;
      align-items: center;
      justify-content: center;
      padding: 10px 15px;
      color: $baseWhite;
      transition: .3s;
      position: relative;
      overflow: hidden;
      z-index: 1;

      &:after{
        content: "";
        position: absolute;
        top: 0;
        z-index: -1;
        transition: .3s;
        opacity: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, #D21E35 0%, #EE3149 100%);
      }
      &:hover:after{
        opacity: 1;
      }
    }
    &__img {
      position: relative;
      border-radius: 8px;
      width: 100%;
      max-width: 229px;
      height: 205px;
      overflow: hidden;
      margin-bottom: 24px;
    }
    &__comparison-delete {
      position: absolute;
      top: 14px;
      right: 18px;
      z-index: 2;
      cursor: pointer;
      transition: all .3s ease;
      &:hover {
        transform: translateY(-3px);
      }
    }
    &__title {
      display: inline-block;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 12px;
      transition: all .3s ease;
      line-height: 20px;
    }
    &__price {
      letter-spacing: 0.06em;
      margin-bottom: 20px;
    }
    &__main-info {
      margin-bottom: 25px;
      .product__info:nth-child(2n) {
        border-radius: 6px;
        background: $lightGray;
      }
      @media(max-width: 768px){
        .product__info {
          padding-left: 20px;
          &:first-child {
            padding-left: 0;          }
        }
      }
    }
    &__main-info:last-child {
      margin-bottom: 0;
    }
    &__info {
      display: flex;
      flex-direction: column;
      &-title{
        font-weight: bold;
        margin-bottom: 3px;
        display: none;
        @media(max-width: 768px){
          display: block;
        }
      }
    }
  }
  &__item-titles .product__info:not(.product__info_back) {
    @media(max-width: 768px){
      display: none;
    }
  }
}
