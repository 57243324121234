.shipping-pay{
    width: 100%;
    position: relative;
    background: $baseWhite;
    padding: 60px;
    padding-bottom: 20px;
    @media(max-width:1450px){
        padding-left: 35px;
        padding-right: 35px;
    }
    @media(max-width:1024px){
        padding: 40px 10px;
        padding-bottom: 10px;
    }


    .pay__container{
        width: 100%;
        max-width: 1520px;
        margin: 0 auto;
        position: relative;

    }

    .pay__title{
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 48px;
        color: $baseBlack;
        max-width: 100%;
        margin-bottom: 30px;
        position: relative;
        max-width: 1000px;
        @media(max-width:1024px){
            font-size: 26px;
            line-height: 32px;
            margin-bottom: 25px;
            padding: 0;
        }
        &:after{
            width:140px ;
            height: 4px;
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            transform: translateY(-30px);
            background: $basePink;
            border-radius: 3px;
            @media(max-width:1024px){
                transform: translateY(-18px);
            }
        }
    }

    .pay__block{
        width: calc(100% + 30px);
        display: flex;
        flex-wrap: wrap;
        @media(max-width:1024px){
            width:calc(100% + 20px);
            margin-left: -10px;
        }
        @media(max-width:576px){
            margin: 0;
            width: 100%;
        }

    }

    .pay__item {
        width: 25%;
        padding:0 15px;
        margin-bottom: 30px;
        @media(max-width:1024px){
            width: 50%;
            padding: 0 10px;
        }
        @media(max-width:576px){
            width: 100%;
            padding: 0;
        }
    }

    .pay__inner {
        width: 100%;
        height: 100%;

    }

    .pay__icon{
        width: 52px;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 25px;
        .icon{
            width: 100%;
            height: 100%;
            max-width: 52px;
            max-height: 52px;
            fill: $basePink;
        }

    }

    .pay__text{
        font-family: Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;
        color: $baseBlack;
    }
}