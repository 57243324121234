.favorites-content{
    width: 100%;
    padding: 40px 60px;
    background: $baseWhite;
    position: relative;
    padding-bottom: 130px;
    @media(max-width:1450px){
        padding: 30px 35px;
        padding-bottom: 80px;
    }
    @media(max-width:1024px){
        padding: 30px 10px;
        padding-bottom: 35px;
    }
    .favorites__container {
        width: 100%;
        position: relative;
        max-width: 1520px;
        margin: 0 auto;
    }

    .products__list{
        width: calc(100% + 30px);
        margin-left: -15px;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        @media(max-width:1024px){
            width: calc(100% + 20px);
            margin-left: -10px;
        }
    }
    .product__item, .product__item.favorite{
        .icon-delete{
            width: 20px;
            height: 20px;
            fill: transparent;
            stroke: $basePink;
        }

        &:hover{
            .icon-delete{
                width: 20px;
                height: 20px;
                fill: transparent;
                stroke: $basePink;
            }
        }
    }
}