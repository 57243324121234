.about-info{
    width: 100%;
    position: relative;
    padding: 30px 60px;
    background: $baseWhite;
    @media(max-width:1450px){
        padding: 30px 35px;
    }
    @media(max-width:1024px){
        padding: 20px 10px;
    }
    .info__container{
        width: 100%;
        max-width: 1520px;
        margin: 0 auto;
    }

    .about__title{
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 48px;
        color: $baseBlack;
        max-width: 100%;
        margin-bottom: 40px;
        position: relative;
        max-width: 1000px;
        @media(max-width:1024px){
            font-size: 26px;
            line-height: 32px;
            margin-bottom: 25px;
            padding: 0;
        }
        &:after{
            width:140px ;
            height: 4px;
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            transform: translateY(-30px);
            background: $basePink;
            border-radius: 3px;
            @media(max-width:1024px){
                transform: translateY(-18px);
            }
        }
    }


    .about__block{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px ;
        @media(max-width:1450px){
            margin-bottom: 0;
            flex-direction: column;
            align-items: center;
        }
    }

    .about__photo {
        width: 50%;
        max-width: 790px;
        display: flex;
        align-items: flex-start;
        // @media(max-width:1450px){
        //     width: 55%;
        // }
        @media(max-width:1450px){
            width: 100%;
            max-width:700px ;
            margin-bottom: 40px;
        }
        @media(max-width:1024px){
            margin-bottom: 25px;
        }
        @media(max-width:768px){
            max-width: 100%;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: contain;

        }
    }
    .about__info {
        width: 50%;
        padding-left: 60px;
        @media(max-width:1450px){
            padding-left: 40px;
            width: 100%;
            padding: 0;
        }
    }
    .about__text{
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 36px;
        color: $baseBlack;
        margin-bottom: 30px;
        @media(max-width:1024px){
            font-size: 16px;
            line-height: 30px;
            margin-bottom: 20px;
        }
    }

    .about-info__text {
        font-family: Gilroy;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 36px;
        color: $baseBlack;
        @media(max-width:1024px){
            font-size: 16px;
            line-height: 30px;
            margin-bottom: 20px;
        }
        p{
            margin-bottom: 26px;
            @media(max-width:1024px){
                margin-bottom: 20px;
            }
        }
        h3{
            font-family: Gilroy;
            font-style: normal;
            font-weight: bold;
            font-size: 26px;
            line-height: 36px;
            margin-bottom: 10px;
            @media(max-width:1024px){
                font-size: 20px;
                line-height: 30px;
            }
        }

        ul{
            margin-bottom: 20px;
            padding-left: 20px;
            @media(max-width:1024px){
                margin-bottom: 15px;
            }
            li{
                list-style-type: disc;
                &::marker{
                    font-size: 12px;
                }
            }
        }
    }

    .certificate__block{
        width: calc(100% + 30px);
        margin-left: -15px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 40px;
        margin-bottom: 60px;
        @media(max-width:1024px){
            width: calc(100% + 20px);
            margin-left: -10px;
            margin-top: 15px;
            margin-bottom: 15px;
        }
        @media(max-width:650px){
            width: 100%;
            margin-left: 0;
        }
    }
    .certificate__item{
        width: 50%;
        padding: 0 15px;
        @media(max-width:1024px){
            padding: 0 10px;
        }
        @media(max-width:650px){
            width: 100%;
            padding: 0;
            margin-bottom:15px ;
            &:last-of-type{
                margin-bottom: 0;
            }
        }
    }

    .item__inner{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;
        padding: 25px 50px;
        border: 1px solid $baseGrey;
        box-sizing: border-box;
        border-radius: 6px;
        @media(max-width:1024px){
            padding: 10px 20px;
        }

    }

    .certificate__img{
        width: 100%;
        max-width:645px;
        height: auto;
        object-fit: contain;

    }

    .country-wrapper {
        width: 100%;
        position: relative;

    }

    .country__text{
        font-family: Gilroy;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 36px;
        margin-bottom: 10px;
        color: $baseBlack;
        @media(max-width:1024px){
            font-size: 16px;
            line-height: 30px;
            margin-bottom: 30px;
        }

    }

    .country__block {
        width: 100%;
        position: relative;
        margin-bottom: 20px;
        @media(max-width:1024px){
            margin-bottom: 10px;
        }
    }

    .area__title{
        font-family: Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 36px;
        color: $baseBlack;
        margin-bottom: 15px;
        @media(max-width:1024px){
            margin-bottom: 20px;
            font-size: 20px;
            line-height: 30px;
        }
    }
    .country__list{
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + 20px);
        margin-left: -10px;

    }

    .country__item{
        width: 100%;
        max-width: 140px;
        padding: 0 10px;
        margin-bottom: 20px;
    }
    .country__inner{
        width: 100%;
        height: 100%;
    }
    .item__flag {
        width: 100%;
        margin-bottom: 12px;
        img{
            width: 120px;
            height: 80px;
        }
    }

    .item__name{
        font-family: Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: $baseBlack;
    }

    .country__subtitle{
        font-family: Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: $baseBlack;
        margin-top: -16px;
        @media(max-width:1024px){
            margin-top: -10px;
        }
    }
}