.stock__content  {
    width: 100%;
    position: relative;
    background: $baseWhite;
    padding:30px 60px;
    padding-bottom: 70px;
    @media(max-width:1450px){
        padding: 20px 35px;
        padding-bottom: 70px;
    }
    @media(max-width:1024px){
        padding: 20px 10px;
        padding-bottom:30px;
    }
    .stock__container {
        width: 100%;
        max-width: 1520px;
        margin: 0 auto;
        
    }

    .stock__title{
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 48px;
        color: $baseBlack;
        max-width: 100%;
        margin-bottom: 30px;
        position: relative;
        @media(max-width:1024px){
            font-size: 26px;
            line-height: 32px;
            margin-bottom: 25px;
            padding: 0;
        }

        &:after{
            width:140px ;
            height: 4px;
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            transform: translateY(-30px);
            background: $basePink;
            border-radius: 3px;
            @media(max-width:1024px){
                transform: translateY(-18px);
            }
        }
    }




    .discount__list {
        width:calc(100% + 30px);
        margin-left: -15px;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        @media(max-width:1024px){
            width:calc(100% + 20px);
            margin-left: -10px;
        }
        @media(max-width:576px){
            margin: 0;
            width: 100%;
        }


    }

    .discount__item {
        padding: 0 15px;
        width: 100%;
        max-width: 33.333333333%;
        margin-bottom: 60px;
        @media(max-width:1024px){
            max-width: 50%;
            padding: 0 10px;
            margin-bottom: 30px;
        }
        @media(max-width:576px){
            max-width: 100%;
            padding: 0;
        }
    }

    .discount__inner {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between; 
    }

    .discount__photo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: 25px;
        @media(max-width:1024px){
            margin-bottom: 20px;
        }
        img{
            width: 100%;
            height: 100%;
            max-height: 260px;
            border-radius: 8px;
            object-fit: contain;
        }
    }
    .discount__name{
        font-family: Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: $baseBlack;
        margin-bottom: 20px;
        @media(max-width:1300px){
            font-size: 18px;
        }
        @media(max-width:1024px){
            margin-bottom: 15px;
        }
    }

    .discount__dates{
        font-family: Gilroy;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 14px;
        color: $baseBlack;
        opacity: 0.5;
        margin-bottom: 20px;
        @media(max-width:1024px){
            margin-bottom: 10px;
        }
    }

    .discount__remain{
        font-family: Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: $baseBlack;
        @media(max-width:1024px){
            font-size: 18px;
            line-height: 26px;
        }
        span{
            color: $basePink;
        }
    }
    
}

.stock-page{
    .error__slider{
        padding: 70px 60px ;
        padding-bottom: 180px;
        @media(max-width:1450px){
            padding: 70px 35px;
            padding-bottom: 180px;
        }
        @media(max-width:1024px){
            padding: 30px 10px;
            padding-bottom: 75px;
        }
    }
}