.modal-success {
  .modal__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-img {
      width: 76px;
      height: 76px;
      margin-bottom: 20px;
    }
  }
  .modal__title {
    text-align: center;
    max-width: 243px;
    margin: 0 auto;
  }
}
